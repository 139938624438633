import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";


export const LoginButton = (props) => {
    const {loginWithRedirect} = useAuth0();
    const {redirect_uri}=props
    if (redirect_uri === undefined) {
        return <button className="btn branded_primary_button" onClick={() => loginWithRedirect({})}>Login</button>;
    }
    else{
         return <button className="btn branded_primary_button" onClick={() => loginWithRedirect({redirectUri:redirect_uri})}>Login</button>;
    }
};

export const LogoutButton = () => {
    const {logout} = useAuth0();

    return (
        <button className="btn branded_primary_button" onClick={() => logout({returnTo: window.location.origin})}>
            Logout
        </button>
    );
};

export const LogoutText = () => {
    const {logout} = useAuth0();

    return (
        <Link className="branded_text" onClick={() => logout({returnTo: window.location.origin})}>
            Logout
        </Link>
    );
};
