import React from "react";
import {FacebookTruncatedTextField} from "./TextTruncators";


function calculateTransformPos(card_number) {
    //With 10 cards these are the transform values
    //taken from instagrams own ad preview
    //needs to be updated to match instagram preview positions
    let card_pos = {
        0: 0,
        1: -318,
        2: -636,
        3: -954,
        4: -1272,
        5: -1590,
        6: -1908,
        7: -2226,
        8: -2544,
        9: -2862,
    }

    let new_pos = card_pos[card_number]

    return new_pos


}


function ModifyCardCount(card_count_setter, card_count, increment, max_num_of_cards) {

    let next_count = card_count + increment
    if (next_count < 0) {
        //if next count will bring count below 0 then set card_count to max num of cards
        //instagram feed ad cards wrap around
        card_count_setter(max_num_of_cards)
    } else if (next_count > max_num_of_cards) {
        //if next count will bring count above max number of cards set card_count to 1
        //instagram feed ad cards wrap around
        card_count_setter(0)
    } else {
        //if count is within min and max count then allow the card_count to increment
        card_count_setter(next_count)

    }


}


function FeedDotBar(props) {

    const max_num_of_cards = props.max_num_of_cards
    const card_count = props.card_count

    let dot_array = []
    for (let i = 0; i < max_num_of_cards; i++) {
        if (i == card_count) {
            dot_array.push(<div key={i} className="_8fry"><i className="_8fqn _8fqo"/></div>)
        } else {
            dot_array.push(<div key={i} className="_8fry"><i className="_8fqn"/></div>)
        }


    }

    return (
        <div className="_8fqq">
            <div className="_3qn7 _61-0 _2fyi _3qng">
                <div>{dot_array}</div>
            </div>
        </div>
    )

}


export default function InstagramFeedAd(props) {

    const card_data_model=props.ad_preview_fields

    const [carousel_x_axis_state, set_carousel_x_axis_state] = React.useState(0)
    //card count increments/decrements on right + left arrow clicks respectively
    const [card_count, set_card_count] = React.useState(0)

    const max_num_of_cards = card_data_model["Cards"].length

    //set current_card_using card count
    console.log(`Card Count Is ${card_count}`)
    const current_card = card_data_model["Cards"][card_count]
    const call_to_action=card_data_model["Call To Action"]
    const company_logo=card_data_model["Logo File Name"]
    const company_name=card_data_model["Company Name"]
    const body=card_data_model["Body"]

    const truncate_body_at_char_num=47



    React.useEffect(() => {
        //on card count change useEffect
        //set state for image px pos to move image cards
        let new_pos = calculateTransformPos(card_count)
        console.log(`Card Count Is ${card_count} Setting X Axis State Pos To ${new_pos}`)
        set_carousel_x_axis_state(new_pos)

        //set state for dot bar
        //length of dot bar depends on number of templates
        //applys and removes class ._8fqo which sets the background property of the small dot to rbg(110, 173, 255) e.g. blue


    }, [card_count])


    return (
        <>
            <link href={"/ad_template_styles/instagram_feed_ad/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>
            <div className="_91kv">
                <div>
                    <div className="_2ph-">
                        <div className="qi2u98y8">
                            <div className="_2qhi">
                                <div className="_8anb"><img className="_8anc img"
                                                            src="https://business.facebook.com/images/instagram/ads/preview/header-background.png"
                                                            alt=""/></div>
                                <div className="_8b2u _3qn7 _61-3 _2fyi _3qng">
                                    <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                        <div className="_3qn7 _61-0 _2fyi _3qnf"><img className="_8b2v img"
                                                                                      src={company_logo}
                                                                                      alt=""/></div>
                                    </div>
                                    <div className="_6g3g" style={{flex: '2 1 auto', order: 0}}>
                                        <div className="_8b2w _3qn7 _61-0 _2fyh _3qnf">
                                            <div className="_6g3g _8b2x"
                                                 style={{flex: '0 1 auto', order: 0}}>{company_name}
                                            </div>
                                            <div className="_6g3g _91wz"
                                                 style={{flex: '0 1 auto', order: 0}}>Sponsored
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                        <div className="_3qn7 _61-0 _2fyi _3qnf"><i alt=""
                                                                                    data-visualcompletion="css-img"
                                                                                    className="img" style={{
                                            backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/Lq_AlGWRrL2.png")',
                                            backgroundPosition: '0px -1469px',
                                            backgroundSize: 'auto',
                                            width: '16px',
                                            height: '16px',
                                            backgroundRepeat: 'no-repeat',
                                            display: 'inline-block'
                                        }}/></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="_8f72">
                                        <div className="_8f73 _3qn7 _61-0 _2fyi _3qnf"
                                             style={{transform: `translateX(${carousel_x_axis_state}px)`}}>
                                            <img className="_8b2z img"
                                                 src={current_card["Product Image File Name"]}
                                                 alt=""/><img className="_8b2z img"
                                                              src={current_card["Product Image File Name"]}
                                                              alt=""/><img className="_8b2z img"
                                                                           src={current_card["Product Image File Name"]}
                                                                           alt=""/><img className="_8b2z img"
                                                                                        src={current_card["Product Image File Name"]}
                                                                                        alt=""/><img
                                            className="_8b2z img"
                                            src={current_card["Product Image File Name"]}
                                            alt=""/><img
                                            className="_8b2z img"
                                            src={current_card["Product Image File Name"]}
                                            alt=""/><img className="_8b2z img"
                                                         src={current_card["Product Image File Name"]}
                                                         alt=""/><img className="_8b2z img"
                                                                      src={current_card["Product Image File Name"]}
                                                                      alt=""/><img className="_8b2z img"
                                                                                   src={current_card["Product Image File Name"]}
                                                                                   alt=""/><img className="_8b2z img"
                                                                                                src={current_card["Product Image File Name"]}
                                                                                                alt=""/></div>
                                        <img className="_8f74 _8f75 img"
                                             src="https://business.facebook.com/images/vault/arrow_left.png"
                                             alt="" onClick={() => {
                                            ModifyCardCount(set_card_count, card_count, -1, max_num_of_cards - 1)
                                        }}/><img
                                        className="_8f74 _8f76 img"
                                        src="https://business.facebook.com/images/vault/arrow_right.png" alt=""
                                        onClick={() => {
                                            ModifyCardCount(set_card_count, card_count, 1, max_num_of_cards - 1)
                                        }}/></div>
                                </div>
                                <div className="_8c1l">
                                    <div className="_8c1m _3qn7 _61-3 _2fyi _3qng">
                                        <div className="_3ma">{call_to_action}</div>
                                        <i alt="" data-visualcompletion="css-img" className="img" style={{
                                            backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/Lq_AlGWRrL2.png")',
                                            backgroundPosition: '-76px -203px',
                                            backgroundSize: 'auto',
                                            width: '12px',
                                            height: '12px',
                                            backgroundRepeat: 'no-repeat',
                                            display: 'inline-block'
                                        }}/></div>
                                </div>
                                <div className="_8fqr">
                                    <div className="_8d5n _3qn7 _61-3 _2fyi _3qng">
                                        <div className="_8fqp _3qn7 _61-1 _2fyi _3qng">
                                            <FeedDotBar max_num_of_cards={max_num_of_cards} card_count={card_count}></FeedDotBar>
                                        </div>
                                        <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                            <div className="_3qn7 _61-0 _2fyi _3qnf">
                                                <div className>
                                                    <button aria-label="Like" className="_8d5q"><img
                                                        className="_8d5o img"
                                                        src="https://business.facebook.com/images/instagram/ads/preview/Like.png"
                                                        alt=""/></button>
                                                </div>
                                                <button aria-label="Comment" className="_8d5q"><img
                                                    className="_8d5o img"
                                                    src="https://business.facebook.com/images/instagram/ads/preview/Comment.png"
                                                    alt=""/></button>
                                                <button aria-label="Share" className="_8d5q"><img className="_8d5o img"
                                                                                                  src="https://business.facebook.com/images/instagram/ads/preview/direct-share.png"
                                                                                                  alt=""/></button>
                                            </div>
                                        </div>
                                        <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                            <button aria-label="Save" className="_8d5q"><img className=" _8d5p img"
                                                                                             src="https://business.facebook.com/images/instagram/ads/preview/save.png"
                                                                                             alt=""/></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="_8dh2" style={{paddingLeft: '10px'}}>
                                    <div><span className="_8dh3 _9hws"/><span/> <span className="_8rs5 _9hvh"
                                                                                      data-ad-preview="ig-message"
                                                                                      direction><span
                                        style={{fontSize: '11px'}} className="newline_behavior">{current_card['Product Name']} <div>
                                        <FacebookTruncatedTextField full_text={body}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...more"></FacebookTruncatedTextField>
                                    </div></span></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden_elem">
                        <div className="_2ph- _228q" id="f269f96d06791c">
                            <div className id="f269f96d06791c-2"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}