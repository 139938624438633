import React from "react";
import error_cat from '../imgs/404_cat.png';
import {Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom"
import {Route, Router} from "react-router";
import background_image from "../imgs/grey-gb-1280.png";


export function PageNotFound() {
    return (
        <Container>
            <div style={{
                backgroundImage: `url(${background_image})`,
                height: '720px',
                width: '100%',
                backgroundColor: ""
            }}>
                <Row className="mt-5">
                    <h1 className="branded_h1">404: Page Not Found</h1>
                </Row>
                <Row>
                    <h4 className="branded_h4">The Page You Are Trying To View Could Not Be Found</h4>
                </Row>
                <Row className={"mt-5"}><h4 className="branded_h4"> Please check your URL or return to the <a
                    href={"/view_all_campaigns"}>Home
                    Page</a>
                </h4></Row>
                <Row>
                </Row>
            </div>
        </Container>
    )
};

export function ResourceNotFound() {
    return (
        <Container>

            <div style={{
                backgroundImage: `url(${background_image})`,
                height: '720px',
                width: '100%',
                backgroundColor: ""
            }}>
                <Row className="mt-5">
                    <h1 className="branded_h1">404: Resource Not Found</h1>
                </Row>
                <Row>
                    <h4 className="branded_h4">The Ad Templates Or Campaigns You Are Trying To View Could Not Be
                        Found</h4>
                </Row>
                <Row className={"mt-5"}><h4 className="branded_h4"> Please Check Your URL Or Return To The <a
                    href={"/view_all_campaigns"}>Home
                    Page</a>
                </h4></Row>
            </div>
        </Container>
    )
};

export function ResourceIsForbidden() {
    return (
        <Container>
            <div style={{
                backgroundImage: `url(${background_image})`,
                height: '720px',
                width: '100%',
                backgroundColor: ""
            }}>
                <Row className="mt-5">
                    <h1 className="branded_h1">403: Resource Is Forbidden</h1>
                </Row>
                <Row>
                    <h4 className={"branded_h4"}>Your Account Does Not Have Permission To View This Page</h4>
                </Row>
                <Row className={"mt-5"}><h4 className="branded_h4"> Please Check Your URL or Return To The <a
                    href={"/view_all_campaigns"}>Home
                    Page</a>
                </h4></Row>
                <Row className={"mt-5"}>
                    <h4 className={"branded_h5"}>Think You Should Have Access? Please Contact Your Account Manager And
                        Share This URL</h4> <i className={"branded_text"}>{window.location.href}</i>
                </Row>

            </div>

        </Container>
    )
};


function InternalError(props) {

    const {error_msg} = props
    return (
        <Container>

            <div style={{
                backgroundImage: `url(${background_image})`,
                height: '720px',
                width: '100%',
                backgroundColor: ""
            }}>
                <Row className={"mt-5"}>
                    <h1 className="branded_h1">An Internal Error Has Occurred</h1>
                </Row>
                <Row>
                    <h4 className="branded_h4">The Development Team Have Been Notified</h4>
                </Row>

                <Row className={"mt-5"}><h4 className="branded_h4">Retry Later or Return To The <a
                    href={"/view_all_campaigns"}>Home
                    Page</a>
                </h4></Row>


                <Row className="mt-5"><h3 className="branded_h5">Error Message</h3></Row>
                <Row>
                    <div className='branded_text'>{error_msg}</div>
                </Row>
            </div>


        </Container>
    )

}

export function SentryErrorBoundaryFallbackFunction(props) {
    /**
     * captures exceptions thrown by syncronous components in app
     * async functions + eventHandlers need to handle errors internally
     * these errors are then actually throw by the syncronous components calling them
     */
    var error_obj = props.error

    //errors we have not captured reach this ErrorBoundary as error objects and need to have the error message explicitly selected
    if (Object.prototype.toString.call(error_obj) === "[object Error]") {
        return (<InternalError error_msg={error_obj.message}></InternalError>)
    }

    //errors captured throughout rest of application via try/catch are thrown again and their message passed to this ErrorBoundary
    //These messages are strings and thus can be searched via .includes
    if (error_obj.includes("404")) {
        return (<ResourceNotFound></ResourceNotFound>)
    }

    if (error_obj.includes("403")) {
        return (<ResourceIsForbidden></ResourceIsForbidden>)
    }


    return (<InternalError error_msg={error_obj}></InternalError>)


};