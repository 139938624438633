import React from "react";
import {FacebookTruncatedTextField} from "./TextTruncators";


function CarouselSeeMoreCard(props) {

    const company_logo_url = props['Logo File Name']
    const company_website = props['Display Link']


    return (
        <div
            className="hqqc2gyo i0osctuo a2epwy6b jdo2dlbp cqv2ihh8 q406jdzx bfxk7ntx qwpvk1j9 groekufi pesago7c b6ewvobd"
            data-ad-preview="carousel-card">
            <div className="i3s5x0kf c7hevu8o i0ppjblf qi2u98y8 mnn3hfhy kctxkyb8" data-ad-preview="image-container"
                 data-react-ad-preview="image-container"><img
                className="qewqowpk pty309aw ooisrqgw gz4g7x96 bfxk7ntx q406jdzx shyhzvm4 img"
                data-react-ad-preview="image-element" height="150"
                src={company_logo_url}
                width="150" alt=""/>
            </div>


            <div
                className="i0ppjblf kctxkyb8 ih2s8k95 okhlmt8k berxdx8z ihd31vdf qp4v5a07 pty309aw gir57bhs sqwpfaiq hwu4s2sy qwpvk1j9 groekufi">
                <div
                    className="shlxu4zg i0ppjblf fkhx5h2b gv7r07jb okxdybxi hm40szxk rzo2rk2c hzphdssp kultw9ig pesago7c b6ewvobd">
                    <div className="i0ppjblf s55pvd0z a5j1tout e946d6ch bdwf4snj df7cgoh5 je5pv78z">
                        <div className="_3qn7 _61-0 _2fyh _1a9e">
                            <div className="e946d6ch idlrdedv iajz466s bdwf4snj _4ik4 _4ik5"
                                 style={{lineHeight: "18px", height: "36px", webKitLineClamp: "2"}}>
                                <div><span className="e946d6ch idlrdedv iajz466s bdwf4snj"
                                           data-ad-preview="card-headline" dir="">See more at {company_website}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        className="qy89712u cs936ovl kvcul83t c77takrd pesago7c b6ewvobd ikxir1m9 krub36ia f2wsvazr s7e84njz"
                        data-ad-preview="card-description" dir=""></span></div>
            </div>
        </div>
    )
}

function CarouselImageCard(props) {
    const headline = props['Product Name']
    const description = props['Product Description']
    const call_to_action = props['Call To Action']
    const image_url = props['Product Image File Name']

    return (

        <div
            className="hqqc2gyo i0osctuo a2epwy6b jdo2dlbp cqv2ihh8 q406jdzx bfxk7ntx qwpvk1j9 groekufi pesago7c b6ewvobd"
            data-ad-preview="carousel-card">
            <div
                className="i3s5x0kf c7hevu8o i0ppjblf qi2u98y8 mnn3hfhy kctxkyb8"
                data-ad-preview="image-container"
                data-react-ad-preview="image-container">
                <img
                    className="qewqowpk pty309aw ooisrqgw gz4g7x96 bfxk7ntx q406jdzx shyhzvm4 img"
                    data-react-ad-preview="image-element"
                    height="230"
                    src={image_url}
                    width="230" alt=""/>
            </div>
            <div
                className="i0ppjblf kctxkyb8 ih2s8k95 okhlmt8k berxdx8z ihd31vdf qp4v5a07 pty309aw gir57bhs sqwpfaiq hwu4s2sy qwpvk1j9 groekufi">
                <div
                    className="shlxu4zg i0ppjblf fkhx5h2b gv7r07jb okxdybxi hm40szxk rzo2rk2c hzphdssp kultw9ig pesago7c b6ewvobd">
                    <div
                        className="i0ppjblf s55pvd0z a5j1tout e946d6ch bdwf4snj df7cgoh5 je5pv78z">
                        <div className="_3qn7 _61-0 _2fyh _1a9e">
                            <div
                                className="e946d6ch idlrdedv iajz466s bdwf4snj _4ik4 _4ik5"
                                style={{
                                    lineHeight: "18px",
                                    height: "36px",
                                    webKitLineClamp: "2"
                                }}>
                                <div>
                                      <span className="e946d6ch idlrdedv iajz466s bdwf4snj"
                                            data-ad-preview="card-headline" dir="">
                                          {headline}
                                      </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        className="qy89712u cs936ovl kvcul83t c77takrd pesago7c b6ewvobd ikxir1m9 krub36ia f2wsvazr s7e84njz"
                        data-ad-preview="card-description" dir="">
                            {description}
                              </span>
                </div>
                <div
                    className="i0ppjblf fkhx5h2b pwxe5gpp i3s5x0kf">
                    <div
                        className="q406jdzx bfxk7ntx qwpvk1j9 groekufi nja3q43t th8rcj18 j7kzsc01 o8b6z0pk l4jw1szp h3cy22fs ptp36d84 h8bbto9c qwuas443 htwdfszv okjml1ls aazhovie ihd31vdf okhlmt8k totcjc31 afqqixrp ikxir1m9 c77takrd dbm02cyx _3qn7 _61-0 _2fyi _3qng">
                        <div data-ad-preview="carousel-cta">
                            <div className="ellipsis"
                                 data-hover="tooltip"
                                 data-tooltip-display="overflow"
                                 style={{
                                     fontFamily: "Arial, sans-serif",
                                     fontSize: "12px",
                                     lineHeight: "16px",
                                     letterSpacing: "normal",
                                     overflowWrap: "normal",
                                     textAlign: "left",
                                     color: "rgb(68, 73, 80)"
                                 }}>
                                    <span>
                                        {call_to_action}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


function CarouselCard(props) {

    const card_data_model = props.card
    const card_type = card_data_model["Card Type"]


    if (card_type === "image_card") {
        return (
            CarouselImageCard(card_data_model)
        )

    }

    if (card_type === "see_more_card") {
        return (
            CarouselSeeMoreCard(card_data_model)
        )
    }


}


function calculateTransformPos(card_number) {
    //With 10 cards these are the transform values
    //taken from facebooks own ad preview
    let card_pos = {
        '1': '0px',
        '2': '-213px',
        '3': '-455px',
        '4': '-697px',
        '5': '-939px',
        '6': '-1181px',
        '7': '-1423px',
        '8': '-1665px',
        '9': '-1907px',
        '10': '-2149px',
        '11': '-2362px'
    }

    let new_pos = card_pos[card_number]
    console.log(`The Position Of This Card Is ${new_pos}`)
    return new_pos


}

export default function FacebookCarouselAdCardsMobile(props) {


    const [carousel_x_axis_state, set_carousel_x_axis_state] = React.useState("0px")
    const carousel_data_model = props.ad_preview_fields

    // const carousel_data_model = {
    //     'company_logo_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t1.18169-1/p200x200/14572816_1818006558468340_5355644869721289164_n.png?_nc_cat=103&ccb=1-3&_nc_sid=dbb9e7&_nc_ohc=yXLTpnCyLlMAX_R-Jod&_nc_ht=scontent.flhr4-4.fna&tp=30&oh=4ebaba38c56c651ac621162d31feccaf&oe=60ED6DC3','site_url':'https:gnomingmegnomingyou.com','company_name':'GnomeingYou',
    //     'carousel_cards': [
    //         {'card_id': '1', 'card_type': 'image_card', 'contents': {'headline': 'Ad 1','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '2', 'card_type': 'image_card', 'contents': {'headline': 'Ad 2','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '3', 'card_type': 'image_card', 'contents': {'headline': 'Ad 3','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '4', 'card_type': 'image_card', 'contents': {'headline': 'Ad 4','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '5', 'card_type': 'image_card', 'contents': {'headline': 'Ad 5','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '6', 'card_type': 'image_card', 'contents': {'headline': 'Ad 6','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '7', 'card_type': 'image_card', 'contents': {'headline': 'Ad 7','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '8', 'card_type': 'image_card', 'contents': {'headline': 'Ad 8','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '9', 'card_type': 'image_card', 'contents': {'headline': 'Ad 9','description':'Get 50% off Garden Gnomes','call_to_action':'LEARN MORE','site_url':'https:gnomingmegnomingyou.com','image_url':'https://scontent.flhr4-4.fna.fbcdn.net/v/t45.1600-4/spS444/c80.0.480.480a/p480x480/212666653_6253547284866_9156814766658984778_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=67cdda&_nc_ohc=8tk-0rafyNkAX9FOnFw&_nc_ht=scontent.flhr4-4.fna&tp=31&oh=5e4d674838b82f2283e5644a9884273b&oe=60EDC5EC'}},
    //         {'card_id': '10', 'card_type': 'see_more_card', 'contents': {'headline': 'Ad 10','company_logo':'https://scontent.flhr4-4.fna.fbcdn.net/v/t1.18169-1/p200x200/14572816_1818006558468340_5355644869721289164_n.png?_nc_cat=103&ccb=1-3&_nc_sid=dbb9e7&_nc_ohc=yXLTpnCyLlMAX_R-Jod&_nc_ht=scontent.flhr4-4.fna&tp=30&oh=4ebaba38c56c651ac621162d31feccaf&oe=60ED6DC3','site_url':'https:gnomingmegnomingyou.com'}}
    //     ]

    const company_logo_url = carousel_data_model["Logo File Name"]
    const company_name = carousel_data_model["Company Name"]
    const body =carousel_data_model["Body"]
    const truncate_body_at_char_num= 101

    //each card is wrapped in a button with an onClick function
    //each card has predefined px position
    //clicking on px position moves carousel to that position
    //cards just hold content
    const carousel_cards = carousel_data_model["Cards"].map((card) =>
        <li id={card.card_id} className="l9j0dhe7" style={{marginLeft: "0px", marginRight: "0px"}}>
            <div role={"button"} onClick={() => {
                set_carousel_x_axis_state(calculateTransformPos(card["Card ID"]))
            }}>
                <CarouselCard card={card}/>
            </div>
        </li>
    )

    return (
        <>
            <link href={"/ad_template_styles/facebook_carousel_ad_mobile/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>
            <div data-testid="ad-preview-mobile-feed-standard" data-react-ad-preview="reactPreviewContainer"
                 className="XXsnipcss_extracted_selector_selectionXX">
                <div
                    //removed class jfj1vgtg which set the margin-left field for this div to auto. This was done to ensure that the ad preview sticks to left of page}
                    //removed class k0b793hi which set pointer-events to none. This was done to allow user to click "See More" to expand text
                    className="apr27be3 ht1y3xug o8axa0rl q2nlfsy2 st4mjv7a gue7836a h7qyaj2j jmqtlm5z  m3gtpzqa">
                    <div
                        className="jdijf8jp pwxe5gpp b8ykynyv jog9zcav gqczokz7 ceubdte7 cqo96w9b s916jpeb q2nlfsy2 i0ppjblf">
                        <div className="okhlmt8k czw6cas6 i0ppjblf">
                            <img className="mnmr0lgl j4tlaqa1 liv2co9e t6rf0jhw sj54pmkn q98s77xp cp0wt59w img"
                                 src={company_logo_url}
                                 alt=""/>
                        </div>
                        <div className="et4myzdo i0ppjblf">
                            <div className="iajz466s ft984vot et4myzdo fkhx5h2b i0ppjblf">
                                <div>
            <span className="taeaecnh e946d6ch od9nxej5">
              <span className="mqfp75mx mr-1">
                  {company_name}
              </span>
              <span>
                shared a
              </span>
              <span className="mqfp75mx ml-1">
                link
              </span>
              <span>
                .
              </span>
            </span>
                                </div>
                                <div className="gf5k81eo o4iun27z qi2u98y8 ozdetmzz f2wsvazr ejsa03q9">
                                    <div className="gf5k81eo">
                                        Sponsored
                                    </div>
                                    ·
                                    <img
                                        src="https://business.facebook.com/images/mobile/privacy/wordmark/everyone.png"
                                        alt=""
                                        className="img"></img>
                                </div>

                            </div>
                            <i className="ofote1xk img sp_05khcnTi6gU sx_96650d" alt=""
                               data-visualcompletion="css-img">
                            </i>
                        </div>
                    </div>

                    <div
                        className="pwxe5gpp b8ykynyv jog9zcav lv2exq23 ceubdte7 fv962s72 s916jpeb gjketrst _3qn7 _61-0 _2fyh _1a9e">
                        <div className="hck7fp40 e946d6ch idlrdedv n3smeuu5" data-ad-preview="message"
                             data-react-ad-preview="message" dir="">
                            <div><span><span className="newline_behavior" id="facebook_body_text"><FacebookTruncatedTextField  full_text={body}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...See More"></FacebookTruncatedTextField></span></span>
                            </div>
                        </div>
                    </div>
                    <div className="fv962s72 ht1y3xug lw3ray1g qi2u98y8">
                        <div className="iiqeiomy jog9zcav gbbjkduh pwxe5gpp gjketrst s916jpeb fv962s72 ceubdte7">
                            <div className="eokf0hev" data-ad-preview="carousel">
                                <div className="d2edcug0 l9j0dhe7 du4w35lb">
                                    <div className="ojkyduve ggysqto6">
                                        <div style={{marginBottom: "-1px", marginTop: "-1px"}}>
                                            <div aria-label="Scrollable Area for Carousel Ad"
                                                 aria-labelledby="jsc_c_0"
                                                 className="o8kakjsu rpm2j7zs k7i0oixp gvuykj2m j83agx80 btwxx1t3 ni8dbmo4 stjgntxs l9j0dhe7 du4w35lb">
                                                <div className="j83agx80 cbu4d94t buofh1pr l9j0dhe7">
                                                    <ul className="j83agx80 btwxx1t3 owycx6da l9j0dhe7"
                                                        style={{
                                                            padding: "0px",
                                                            webKitTransition: 'ls ease-in-out',
                                                            transform: `translateX(${carousel_x_axis_state})`
                                                        }}>
                                                        {carousel_cards}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="aesv1g1x _3qn7 _61-0 _2fyh _3qnf">
                        <div className="_6g3g ouqzbsnu py2yxmsk jog9zcav a0z8vrwm pwxe5gpp"
                             style={{flex: "0 1 auto", order: "0"}}>
                            <div className="_3qn7 _61-0 _2fyi _3qnf">
                                <div className="_3qn7 _61-0 _2fyi _3qnf">
                                </div>
                                <div className="_6g3g tvbmclkk e946d6ch g8d9r7l5"
                                     style={{flex: "1 0 auto", order: "0"}}>
                                    <div className="_3qn7 _61-2 _2fyi _3qnf">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="r3gg3nyv o0173g8g jfj1vgtg st4mjv7a ouqzbsnu">
                        </div>
                        <div className="_6g3g ouqzbsnu ih2s8k95 jog9zcav c0cqrad2 pwxe5gpp"
                             style={{flex: "0 1 auto", order: "0"}}>
                            <div className="q08bcfxv tvbmclkk _3qn7 _6twk _2fyi _3qnf">
                                <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                    <div className="_3qn7 _61-0 _2fyi _3qnf">
                                        <div className="_6g3g jess0bhw dtpbil0c okhlmt8k"
                                             style={{flex: "0 1 auto", order: "0"}}>
                                            <i alt="" data-visualcompletion="css-img"
                                               className="img sp_oSfsMckyHL2 sx_5c20ab">
                                            </i>
                                        </div>
                                        <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                            Like
                                        </div>
                                    </div>
                                </div>
                                <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                    <div className="_3qn7 _61-0 _2fyi _3qnf">
                                        <div className="_6g3g jess0bhw dtpbil0c okhlmt8k"
                                             style={{flex: "0 1 auto", order: "0"}}>
                                            <i alt="" data-visualcompletion="css-img"
                                               className="img sp_oSfsMckyHL2 sx_3b5726">
                                            </i>
                                        </div>
                                        <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                            Comment
                                        </div>
                                    </div>
                                </div>
                                <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                    <div className="_3qn7 _61-0 _2fyi _3qnf">
                                        <div className="_6g3g jess0bhw dtpbil0c okhlmt8k"
                                             style={{flex: "0 1 auto", order: "0"}}>
                                            <i alt="" data-visualcompletion="css-img"
                                               className="img sp_oSfsMckyHL2 sx_411ece">
                                            </i>
                                        </div>
                                        <div className="_6g3g" style={{flex: "0 1 auto", order: "0"}}>
                                            Share
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}