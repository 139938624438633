import React from 'react';
import {FacebookTruncatedTextField} from "./TextTruncators";


function CompanyOverlay(props) {

    const data_model = props.data_model
    const primary_text = data_model['Body']
    const company_name = data_model['Company Name']
    const call_to_action = data_model['Call To Action']
    const display_url = data_model['Display URL']
    const logo_url = data_model['Logo File Name']
    const image_url = data_model['Image File Name']
    const {truncate_body_at_char_num} = props


    return (
        <div>
            <div className>
                <div>
                    <div>
                        <div className="_7vpk">
                            <div className="_831c">
                                <div className="_7wp8"/>
                                <div className="_91kv">
                                    <div>
                                        <div className>
                                            <div className="qi2u98y8">
                                                <div className="_ogl">
                                                    <div className="_2qhi" id="instagram_story_preview_frame"
                                                         style={{background: 'linear-gradient(rgb(18, 22, 8), rgb(197, 201, 201))'}}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-evenly',
                                                            height: 'inherit'
                                                        }}>
                                                            <div className="_ogm"/>
                                                            <div className="rn4cszh3 r0xniltx kar0e7i4 ec8s3276"
                                                                 style={{
                                                                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                     top: '0px',
                                                                     transition: 'background-color 1s ease-in 1s, top 0.5s ease-in 0s'
                                                                 }}/>
                                                            <div
                                                                className="a53abz89 rgsc13q7 a5qkma2g dfy4e4am rwb8dzxj hv94jbsx olu7ajlf rn4cszh3 r0xniltx kar0e7i4 ec8s3276"
                                                                style={{
                                                                    top: '-20px',
                                                                    zIndex: 5,
                                                                    opacity: 1,
                                                                    transition: 'opacity 1s linear 0s, top 1s linear 0s'
                                                                }}>
                                                                <div
                                                                    className="js5hhb28 bdwf4snj kyh3c3tg qh880m3g fmhvxro1 f36t4bwu fo07zald">{company_name}
                                                                </div>
                                                                <div className="kyh3c3tg n1uysli7">{display_url}</div>
                                                                <div>
                                                                    <div
                                                                        className=" _9hzz apr27be3 n9ac1b9y pag1bcgd iz25m6y8 olqj94ss hck7fp40 f2wsvazr bdwf4snj gqczokz7 jog9zcav h213ssg1 pwxe5gpp k0b793hi iohei9zn ofsvll6k m28dwwiu">{call_to_action}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="_86n" style={{zIndex: 1}}>
                                                                <div>
                                                                    <div
                                                                        className="lcqqo40c cj07v147 rn4cszh3 k0b793hi t6hts89f ec9ft2wg g06gshxt lls8mtav tq5r2qxz h0343eac"
                                                                        style={{left: '4px', right: '4px'}}>
                                                                        <div style={{
                                                                            backgroundColor: 'var(--fds-white)',
                                                                            borderRadius: '1px',
                                                                            height: '100%',
                                                                            width: '0%',
                                                                            transition: 'width 0s ease 0s'
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <img className="_ogn"
                                                                     src={logo_url}/>
                                                                <div className="_86q">{company_name}</div>
                                                                <div className="_4z2a">Sponsored</div>
                                                                <img className="_86v"
                                                                     src="https://business.facebook.com/images/instagram/ads/preview/story-ellipsis.png"/><img
                                                                className="_86t"
                                                                src="https://business.facebook.com/images/instagram/ads/preview/story-close.png"/>
                                                            </div>
                                                            <img className="_7lg2" id="instagram_story_preview_media"
                                                                 src={image_url}/>
                                                            <div style={{marginBottom: '63px'}}>
                                                                <div className="_9uea"
                                                                     id="non_9_x_16_story_caption_block" style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    padding: '10px 26px',
                                                                    width: '318px'
                                                                }}>
                                                                    <div><span className="_7o7s" dir
                                                                               id="non_9_x_16_story_caption_text"
                                                                               style={{
                                                                                   color: 'rgb(255, 255, 255)',
                                                                                   fontSize: '13px',
                                                                                   fontWeight: 'bold',
                                                                                   fontFamily: 'Tahoma, Helvetica, Arial, sans-serif',
                                                                                   backgroundColor: 'rgb(0, 0, 0)'
                                                                               }}><span className="_8rs5 _9hvh"
                                                                                        data-ad-preview="ig-message"
                                                                                        direction><span
                                                                        style={{fontSize: '21px',}} className={"newline_behavior"}><FacebookTruncatedTextField
                                                                        className={"newline_behavior"}
                                                                        full_text={primary_text}
                                                                        truncate_at_char_num={truncate_body_at_char_num}
                                                                        text_for_toggle="...More"></FacebookTruncatedTextField></span></span></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden_elem">
                                            <div className=" _228q" id="f38b17b1fa187e">
                                                <div className id="f38b17b1fa187e-2"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

function FirstCard(props) {

    const data_model = props.data_model
    const primary_text = data_model['Body']
    const company_name = data_model['Company Name']
    const call_to_action = data_model['Call To Action']
    const display_url = data_model['Display URL']
    const logo_url = data_model['Logo File Name']
    const image_url = data_model['Image File Name']
    const {truncate_body_at_char_num} = props


    return  (<div>
        <div className>
            <div>
                <div>
                    <div className="_7vpk">
                        <div className="_831c">
                            <div className="_7wp8"/>
                            <div className="_91kv">
                                <div>
                                    <div className>
                                        <div className="qi2u98y8">
                                            <div className="_ogl">
                                                <div className="_2qhi" id="instagram_story_preview_frame"
                                                     style={{background: 'linear-gradient(rgb(18, 22, 8), rgb(197, 201, 201))'}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-evenly',
                                                        height: 'inherit'
                                                    }}>
                                                        <div className="_ogm"/>
                                                        <div className="rn4cszh3 r0xniltx kar0e7i4"
                                                             style={{
                                                                 backgroundColor: 'rgba(0, 0, 0, 0)',
                                                                 top: '100px',
                                                                 transition: 'all 0s ease 0s'
                                                             }}/>
                                                        <div
                                                            className="a53abz89 rgsc13q7 a5qkma2g dfy4e4am rwb8dzxj hv94jbsx olu7ajlf rn4cszh3 r0xniltx kar0e7i4"
                                                            style={{
                                                                top: '0px',
                                                                zIndex: 0,
                                                                opacity: 0,
                                                                transition: 'all 0s ease 0s'
                                                            }}>
                                                            <div
                                                                className="js5hhb28 bdwf4snj kyh3c3tg qh880m3g fmhvxro1 f36t4bwu fo07zald">{company_name}
                                                            </div>
                                                            <div className="kyh3c3tg n1uysli7">{display_url}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="apr27be3 n9ac1b9y pag1bcgd iz25m6y8 olqj94ss hck7fp40 f2wsvazr bdwf4snj gqczokz7 jog9zcav h213ssg1 pwxe5gpp k0b793hi iohei9zn ofsvll6k m28dwwiu">{call_to_action}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_86n" style={{zIndex: 1}}>
                                                            <div>
                                                                <div
                                                                    className="lcqqo40c cj07v147 rn4cszh3 k0b793hi t6hts89f ec9ft2wg g06gshxt lls8mtav tq5r2qxz h0343eac"
                                                                    style={{left: '4px', right: '4px'}}>
                                                                    <div style={{
                                                                        backgroundColor: 'var(--fds-white)',
                                                                        borderRadius: '1px',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        transition: 'width 9s linear 0s'
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <img className="_ogn"
                                                                 src={logo_url}/>
                                                            <div className="_86q">{company_name}</div>
                                                            <div className="_4z2a">Sponsored</div>
                                                            <img className="_86v"
                                                                 src="https://business.facebook.com/images/instagram/ads/preview/story-ellipsis.png"/><img
                                                            className="_86t"
                                                            src="https://business.facebook.com/images/instagram/ads/preview/story-close.png"/>
                                                        </div>
                                                        <img className="_7lg2"
                                                             id="instagram_story_preview_media"
                                                             src={image_url}/>
                                                        <div style={{marginBottom: '63px'}}>
                                                            <div className="_9uea"
                                                                 id="non_9_x_16_story_caption_block" style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                padding: '10px 26px',
                                                                width: '318px'
                                                            }}>
                                                                <div><span className="_7o7s" dir
                                                                           id="non_9_x_16_story_caption_text"
                                                                           style={{
                                                                               color: 'rgb(255, 255, 255)',
                                                                               fontSize: '13px',
                                                                               fontWeight: 'bold',
                                                                               fontFamily: 'Tahoma, Helvetica, Arial, sans-serif',
                                                                               backgroundColor: 'rgb(0, 0, 0)'
                                                                           }}><span className="_8rs5 _9hvh"
                                                                                    data-ad-preview="ig-message"
                                                                                    direction><span
                                                                    style={{fontSize: '21px', zIndex:2,
                                                                        position:"relative",cursor:"default"}} className={"newline_behavior"}><FacebookTruncatedTextField className="newline_behavior"full_text={primary_text}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...More"></FacebookTruncatedTextField></span></span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="_9hzx"><img className="_9hzy img"
                                                                                src="https://business.facebook.com/images/instagram/ads/preview/viewer_swipeup.png"
                                                                                alt=""/>
                                                        <div>
                                                            <div className="_9hzz">{call_to_action}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden_elem">
                                        <div className=" _228q" id="f38b17b1fa187e">
                                            <div className id="f38b17b1fa187e-2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}


function CardSwitch(props) {
    const card_type = props.card_type
    const data_model = props.data_model
    const {truncate_body_at_char_num} = props

    if (card_type === "first_card") {
        return (<FirstCard data_model={data_model} truncate_body_at_char_num={truncate_body_at_char_num}></FirstCard>)
    }

    if (card_type === "company_overlay") {
        return (<CompanyOverlay data_model={data_model}
                                truncate_body_at_char_num={truncate_body_at_char_num}></CompanyOverlay>)

    }

}


function toggle_state(current_state, state_setter) {

    let state_to_set = ""
    if (current_state === "first_card") {
        state_to_set = "company_overlay"
    }

    if (current_state === "company_overlay") {
        state_to_set = "first_card"
    }

    console.log(`Current State Is "${current_state}" Setting State To "${state_to_set}"`)
    state_setter(state_to_set)

}

export default function InstagramSingleImageStoryAd(props) {


    const data_model = props.ad_preview_fields
    const [card_state, set_card_state] = React.useState("first_card")

    const card_delay_ms = 8000

    const truncate_body_at_char_num = 64

    //adding card_state as a depency forces useEffect to re-run on every render
    //this forces the setInterval function to be reconstructed with the latest value of card_state!
    React.useEffect(() => {
        const interval = setInterval(() => {
            console.log(`Logs every ${card_delay_ms} milli-seconds`);
            toggle_state(card_state, set_card_state)
        }, card_delay_ms);

        return () => clearInterval(interval);
    }, [card_state])


    return (
        <>
            <link href={"/ad_template_styles/instagram_single_image_story_ad/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>

            <CardSwitch card_type={card_state} data_model={data_model}
                        truncate_body_at_char_num={truncate_body_at_char_num}></CardSwitch>

        </>
    )

}