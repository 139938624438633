import React from "react"
import {Link, useHistory} from "react-router-dom"
import * as auth0 from "../auth/auth0_login";
import {useAuth0} from "@auth0/auth0-react";
import {Nav, Navbar, Button} from 'react-bootstrap'
import brand_logo from '../imgs/semetrical_logo_horizontal.png'
import {useLocation} from "react-router";


function ApplicationNav() {
    const history = useHistory()
    return (
        <Navbar className="page_header">
            <Navbar.Brand><img src={brand_logo} width="180" height="35"/></Navbar.Brand>

            <Nav className={"ml-auto"}>
                <Nav.Item className={"mr-5"}><Link className={"branded_text"} to="/view_all_campaigns">
                    Campaigns</Link></Nav.Item>

                <Nav.Item className={"mr-5"}>
                    <auth0.LogoutText/>
                </Nav.Item>
            </Nav>


        </Navbar>
    );

}


export default function AppNavbar() {
    const location = useLocation()


    if (location.pathname.indexOf("view_template_variation") == 1) {
        return (<></>)
    }


    if (location.pathname === "/") {
        return (<></>)
    }


    return (<ApplicationNav/>)
}