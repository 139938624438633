import React from "react";
import {FacebookTruncatedTextField} from "./TextTruncators";


export default function FacebookSingleImageAdMobile(props) {
    const ad_template_fields = props.ad_preview_fields
    const ad_logo=ad_template_fields['Logo File Name']
    const ad_template_image_url=ad_template_fields['Image File Name']
    const title=ad_template_fields['Title']
    const body=ad_template_fields['Body']
    const display_link=ad_template_fields['Display Link']
    const call_to_action=ad_template_fields['Call To Action']
    const company_name=ad_template_fields['Company Name']
    const link_description=ad_template_fields['Link Description']

    const truncate_body_at_char_num=101

    return (
        <>
            <link href={"/ad_template_styles/facebook_single_image_ad_mobile/styles/preview_css.css"} rel={"stylesheet"} type={"text/css"}/>
            <div
                className="m3gtpzqa jmqtlm5z h7qyaj2j gue7836a q2nlfsy2 o8axa0rl ht1y3xug apr27be3">
                <div
                    className="jdijf8jp pwxe5gpp b8ykynyv jog9zcav gqczokz7 ceubdte7 cqo96w9b s916jpeb q2nlfsy2 i0ppjblf">
                    <div className="okhlmt8k czw6cas6 i0ppjblf"><img alt=""
                                                                     className="mnmr0lgl j4tlaqa1 liv2co9e t6rf0jhw sj54pmkn q98s77xp cp0wt59w img"
                                                                     src={ad_logo}/>
                    </div>
                    <div className="et4myzdo i0ppjblf">
                        <div className="iajz466s ft984vot et4myzdo fkhx5h2b i0ppjblf">
                            <div><span className="taeaecnh e946d6ch od9nxej5"><span
                                className="mqfp75mx">{company_name}</span></span></div>
                            <div className="gf5k81eo o4iun27z qi2u98y8 ozdetmzz f2wsvazr ejsa03q9">
                                <div className="gf5k81eo">Sponsored</div>
                                · <img alt="" className="img"
                                       src="/ad_template_styles/facebook_single_image_ad_mobile/styles/everyone.png"/>
                            </div>
                        </div>
                        <i alt="" className="ofote1xk img sp__rMRFx0rtRp sx_00cdb7"></i></div>
                </div>
                <div
                    className="pwxe5gpp b8ykynyv jog9zcav lv2exq23 ceubdte7 fv962s72 s916jpeb gjketrst _3qn7 _61-0 _2fyh _1a9e">
                    <div className="n3smeuu5 idlrdedv e946d6ch k8hy91ck" data-ad-preview="message"
                         data-react-ad-preview="message" dir="">
                        <div className={"newline_behavior"} id={"facebook_body_text"} dir=""><span><span><FacebookTruncatedTextField full_text={body}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...See More"></FacebookTruncatedTextField></span></span>
                        </div>
                    </div>
                </div>
                <div className="fv962s72 ht1y3xug lw3ray1g qi2u98y8">
                    <div className="qi2u98y8">
                        <div className="fpashsu6 reh35m8s lcqqo40c ht1y3xug l9eb8aut rn4cszh3"></div>
                        <div className="qi2u98y8" data-ad-preview="image-container"
                             data-react-ad-preview="image-container">
                            <img alt="" className="shyhzvm4 pzc0nz0h img" data-ad-preview="image"
                                 data-react-ad-preview="image" height="320"
                                 src={ad_template_image_url}
                                 width="320"/></div>
                        <div className="df7cgoh5 hfwk86yq oa8xhnpz q53fqi8x d5915en0 smifbl6g _3qn7 _61-0 _2fyi _3qng">
                            <div
                                className="_6g3g gir57bhs n3smeuu5 b6ewvobd pesago7c rzo2rk2c flex: 1 1 auto; order: 0;"
                            >
                                <div>
                                    <div className="krub36ia iajz466s" data-ad-preview="display-link-container"><span
                                        className="dbm02cyx f2wsvazr _6i6a color: rgb(70, 73, 80);"><span
                                    >{display_link}</span></span></div>
                                    <div className="lvqxqreb">
                                        <div className="_3qn7 _61-0 _2fyh _1a9e">
                                            <div
                                                className="e946d6ch bdwf4snj idlrdedv iajz466s _4ik4 _4ik5 line-height: 18px; height: 36px; -webkit-line-clamp: 2;"
                                            >
                                                <div><span className="e946d6ch bdwf4snj idlrdedv iajz466s"
                                                           data-ad-preview="headline"
                                                           dir="">{title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-ad-preview="link-description" dir=""><span
                                    className="#464950 _6i6a color: rgb(70, 73, 80);">{link_description}</span>
                                </div>
                            </div>
                            <div className="hrmoewb7">
                                <div
                                    className="q406jdzx bfxk7ntx qwpvk1j9 groekufi nja3q43t th8rcj18 j7kzsc01 o8b6z0pk l4jw1szp h3cy22fs ptp36d84 h8bbto9c qwuas443 htwdfszv okjml1ls aazhovie ihd31vdf okhlmt8k totcjc31 afqqixrp ikxir1m9 c77takrd dbm02cyx _3qn7 _61-0 _2fyi _3qng">
                                    <div data-ad-preview="cta">
                                        <div
                                            className="ellipsis font-family: Arial, sans-serif; font-size: 12px; line-height: 16px; letter-spacing: normal; overflow-wrap: normal; text-align: left; color: rgb(68, 73, 80);"
                                            data-hover="tooltip" data-tooltip-display="overflow"
                                        >
                                            <span>{call_to_action}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nt5oj4yo s6mbirzi lcqqo40c ht1y3xug n73mk9bw rn4cszh3"></div>
                    </div>
                </div>
                <div className="aesv1g1x _3qn7 _61-0 _2fyh _3qnf">
                    {/* this code generates the like button and number of likes*/}
                    {/*<div className="_6g3g pwxe5gpp a0z8vrwm jog9zcav py2yxmsk ouqzbsnu flex: 0 1 auto; order: 0;">*/}
                    {/*    <div className="_3qn7 _61-0 _2fyi _3qnf">*/}
                    {/*        <div className="_3qn7 _61-0 _2fyi _3qnf">*/}
                    {/*            <div className="_6g3g du99mqeq flex: 0 1 auto; order: 0;"><span*/}
                    {/*                className="_9zc _9--"><i className="_3j7l _2p78 _9--"></i></span></div>*/}
                    {/*        </div>*/}
                    {/*        <div className="_6g3g hm40szxk tufop14j lyz2xu1t flex: 0 1 auto; order: 0;">*/}
                    {/*            <div*/}
                    {/*                className="ellipsis font-family: Arial, sans-serif; font-size: 14px; line-height: 18px; letter-spacing: normal; overflow-wrap: normal; text-align: left; color: rgb(96, 103, 112);"*/}
                    {/*                data-hover="tooltip" data-tooltip-display="overflow"*/}
                    {/*            >2*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="_6g3g e946d6ch tvbmclkk g8d9r7l5 flex: 1 0 auto; order: 0;">*/}
                    {/*            <div className="_3qn7 _61-2 _2fyi _3qnf"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="ouqzbsnu st4mjv7a jfj1vgtg o0173g8g r3gg3nyv"></div>
                    <div className="_6g3g pwxe5gpp c0cqrad2 jog9zcav ih2s8k95 ouqzbsnu flex: 0 1 auto; order: 0;">
                        <div className="tvbmclkk q08bcfxv _3qn7 _6twk _2fyi _3qnf">
                            <div className="_6g3g flex: 0 1 auto; order: 0;">
                                <div className="_3qn7 _61-0 _2fyi _3qnf">
                                    <div className="_6g3g dtpbil0c okhlmt8k flex: 0 1 auto; order: 0;"><i alt=""
                                                                                                          className="img sp_3ml9p-EoR8n sx_7643e2"></i>
                                    </div>
                                    <div className="_6g3g flex: 0 1 auto; order: 0;">Like</div>
                                </div>
                            </div>
                            <div className="_6g3g flex: 0 1 auto; order: 0;">
                                <div className="_3qn7 _61-0 _2fyi _3qnf">
                                    <div className="_6g3g dtpbil0c okhlmt8k flex: 0 1 auto; order: 0;"><i alt=""
                                                                                                          className="img sp__rMRFx0rtRp sx_e0bc92"></i>
                                    </div>
                                    <div className="_6g3g flex: 0 1 auto; order: 0;">Comment</div>
                                </div>
                            </div>
                            <div className="_6g3g flex: 0 1 auto; order: 0;">
                                <div className="_3qn7 _61-0 _2fyi _3qnf">
                                    <div className="_6g3g dtpbil0c okhlmt8k flex: 0 1 auto; order: 0;"><i alt=""
                                                                                                          className="img sp__rMRFx0rtRp sx_dc9b73"></i>
                                    </div>
                                    <div className="_6g3g flex: 0 1 auto; order: 0;">Share</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


