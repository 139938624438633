import React from "react";
import {FacebookTruncatedTextField} from "./TextTruncators";


export default function FacebookSingleImageAdDesktop(props) {
    const ad_template_fields = props.ad_preview_fields
    const ad_logo = ad_template_fields['Logo File Name']
    const ad_template_image_url = ad_template_fields['Image File Name']
    const title = ad_template_fields['Title']
    const body = ad_template_fields['Body']
    const display_link = ad_template_fields['Display Link']
    const call_to_action = ad_template_fields['Call To Action']
    const company_name = ad_template_fields['Company Name']
    const link_description = ad_template_fields['Link Description']

    const truncate_body_at_char_num=481



 return (
     <>
        <link href={"/ad_template_styles/facebook_single_image_ad_desktop/styles/preview_css.css"} rel={"stylesheet"}
                 type={"text/css"}/>
        <div className="l9j0dhe7 stjgntxs ni8dbmo4 izx4hr6d humdl8nn bn081pho gcieejh5" style={{width: "502px"}}>
        <div>
        <div className="du4w35lb k4urcfbm l9j0dhe7 sjgh65i0">
        <div className="du4w35lb l9j0dhe7">
        <div className="">
        <div className="">
        <div aria-describedby="jsc_c_d jsc_c_e jsc_c_f jsc_c_h jsc_c_g"
                                             aria-labelledby="jsc_c_c"
                                             className="lzcic4wl" role="article">
                                            <div className="j83agx80 cbu4d94t">
                                                <div className="rq0escxv l9j0dhe7 du4w35lb">
                                                    <div className="j83agx80 l9j0dhe7 k4urcfbm">
                                                        <div
                                                            className="rq0escxv l9j0dhe7 du4w35lb hybvsw6c ue3kfks5 pw54ja7n uo3d90p7 l82x9zwi ni8dbmo4 stjgntxs k4urcfbm sbcfpzgs"
                                                            style={{borderRadius: "max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px"}}>
                                                            <div>
                                                                <div></div>
                                                                <div>
                                                                    <div>
                                                                        <div></div>
                                                                        <div>
                                                                            <div
                                                                                className="pybr56ya dati1w0a hv4rvrfc n851cfcs btwxx1t3 j83agx80 ll8tlv6m">
                                                                                <div className="oi9244e8 do00u71z j83agx80">
                                                                                    <div className="nc684nl6">
                                                                                        <img id="aap_ad_facebook_single_image_ad_desktop" src={ad_logo}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="buofh1pr">
                                                                                    <div
                                                                                        className="j83agx80 cbu4d94t ew0dbk1b irj2b8pg">
                                                                                        <div
                                                                                            className="qzhwtbm6 knvmm38d"><span
                                                                                            className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v knj5qynh m9osqain hzawbc8m"
                                                                                            dir="auto"><h2
                                                                                            className="gmql0nx0 l94mrbxd p1ri9a11 lzcic4wl aahdfvyu hzawbc8m"
                                                                                            dir="auto" id="jsc_c_c"><div
                                                                                            className="nc684nl6"><a
                                                                                            className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 nc684nl6 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl oo9gr5id gpro0wi8 lrazzd5p"
                                                                                            href={ad_logo}
                                                                                            role="link"
                                                                                            tabIndex="0"><strong><span>{company_name}</span></strong></a></div></h2></span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="qzhwtbm6 knvmm38d"><span
                                                                                            className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 tia6h79c fe6kdd0r mau55g9w c8b282yb iv3no6db e9vueds3 j5wam9gi knj5qynh m9osqain hzawbc8m"
                                                                                            dir="auto"><span
                                                                                            id="jsc_c_d"><span
                                                                                            className="jpp8pzdo"><span><span
                                                                                            className="rfua0xdk pmk7jnqg stjgntxs ni8dbmo4 ay7djpcl q45zohi1">&nbsp;</span><span
                                                                                            aria-hidden="true"> · </span></span></span><a
                                                                                            aria-label="Sponsored"
                                                                                            className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 nc684nl6 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl gmql0nx0 gpro0wi8 b1v8xokw"
                                                                                            href="https://www.facebook.com/ads/experience/confirmation/?experience_id=1147667218984601&amp;__fns&amp;ext=1607958161&amp;hash=Ac3Xpr62gjbjdoEzIVs#"
                                                                                            role="link"
                                                                                            tabIndex="0">Sponsored</a><span
                                                                                            className="jpp8pzdo"><span><span
                                                                                            className="rfua0xdk pmk7jnqg stjgntxs ni8dbmo4 ay7djpcl q45zohi1">&nbsp;</span><span
                                                                                            aria-hidden="true"> · </span></span></span><span
                                                                                            className="g0qnabr5"><span
                                                                                            className="tojvnm2t a6sixzi8 abs2jz4q a8s20v7p t1p8iaqh k5wvi7nf q3lfd5jv pk4s997a bipmatt0 cebpdrjk qowsmv63 owwhemhu dp1hu0rb dhp61c6y iyyx5f41"><span
                                                                                            className="ormqv51v l9j0dhe7"><i
                                                                                            aria-label="Shared with Public"
                                                                                            className="hu5pjgll m6k467ps sp_Md36BmaRPC2 sx_b43195"
                                                                                            role="img"></i></span></span></span></span></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="nqmvxvec j83agx80 jnigpg78 cxgpxx05 dflh9lhu sj5x9vvc scb9dxdr odw8uiq3">
                                                                                    <div aria-expanded="false"
                                                                                         aria-haspopup="menu"
                                                                                         aria-label="Actions for this post"
                                                                                         className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 pq6dq46d mg4g778l btwxx1t3 pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb lzcic4wl abiwlrkh p8dawk7l dwo3fsh8 pzggbiyp pkj7ub1o bqnlxs5p kkg9azqs c24pa1uk ln9iyx3p fe6kdd0r ar1oviwq l10q8mi9 sq40qgkc s8quxz6p pdjglbur"
                                                                                         role="button" tabIndex="0"><i
                                                                                        className="hu5pjgll m6k467ps sp_Md36BmaRPC2 sx_144c11"></i>
                                                                                        <div
                                                                                            className="s45kfl79 emlxlaya bkmhp75w spb7xbtv i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                            data-visualcompletion="ignore"
                                                                                            style={{bottom: "-8px" ,left: "-8px" ,right: "-8px" , top: "-8px"}}></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="" dir="auto">
                                                                                <div
                                                                                    className="ecm0bbzt hv4rvrfc ihqw7lf3 dati1w0a"
                                                                                    data-ad-comet-preview="message"
                                                                                    data-ad-preview="message" id="jsc_c_e">
                                                                                    <div
                                                                                        className="j83agx80 cbu4d94t ew0dbk1b irj2b8pg">
                                                                                        <div
                                                                                            className="qzhwtbm6 knvmm38d"><span
                                                                                            className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v knj5qynh oo9gr5id hzawbc8m"
                                                                                            dir="auto"><div
                                                                                            className="kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x c1et5uql ii04i59q"><div
                                                                                            dir="auto"
                                                                                            style={{textAlign: "start"}}><FacebookTruncatedTextField className={"newline_behavior"} id={"facebook_body_text"} full_text={body}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...See More"></FacebookTruncatedTextField></div></div></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="l9j0dhe7" id="jsc_c_f">
                                                                                <div className="l9j0dhe7">
                                                                                    <div>
                                                                                        <div className="b3i9ofy5 l9j0dhe7">
                                                                                            <div
                                                                                                className="j83agx80 soycq5t1 ni8dbmo4 stjgntxs l9j0dhe7">
                                                                                                <a className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 nc684nl6 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl gmql0nx0 gpro0wi8 datstx6m k4urcfbm"
                                                                                                   href={ad_template_image_url}
                                                                                                   rel="nofollow noopener"
                                                                                                   role="link" tabIndex="0"
                                                                                                   target="_blank">
                                                                                                    <div
                                                                                                        className="bp9cbjyn tqsryivl j83agx80 cbu4d94t ni8dbmo4 stjgntxs l9j0dhe7 k4urcfbm">
                                                                                                        <div
                                                                                                            style={{maxWidth: "100%", minWidth: "500px", width: "calc((100vh + -325px) * 1)"}}>
                                                                                                            <div
                                                                                                                className="do00u71z ni8dbmo4 stjgntxs l9j0dhe7"
                                                                                                                style={{paddingTop: "100%"}}>
                                                                                                                <div
                                                                                                                    className="pmk7jnqg kr520xx4"
                                                                                                                    style={{height: "100%", left: "0%", width: "calc(100%)"}}>
                                                                                                                    <img
                                                                                                                        alt="Crown Pavilions Luxury Garden Rooms"
                                                                                                                        className="i09qtzwb n7fi1qx3 datstx6m pmk7jnqg j9ispegn kr520xx4 k4urcfbm bixrwtb6"
                                                                                                                        height="275"
                                                                                                                        src={ad_template_image_url}
                                                                                                                        width="526"/>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="i09qtzwb rq0escxv n7fi1qx3 pmk7jnqg j9ispegn kr520xx4 linmgsc8 opwvks06 hzruof5a"></div>
                                                                                                </a></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="stjgntxs ni8dbmo4">
                                                                                    <div className="l9j0dhe7"><a
                                                                                        className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 a8c37x1j p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl gmql0nx0 p8dawk7l"
                                                                                        href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.crownpavilions.com%2Fgarden-room-range%2F%3Futm_source%3D%257B%257Bsite_source_name%257D%257D%26utm_medium%3Dcpc%26utm_campaign%3D%257B%257Bcampaign.name%257D%257D%26utm_content%3D%257B%257Bad.id%257D%257D%26fbclid%3DIwAR3irswW9N_cTZqqHtrqB8LtWyXVyTFxdd8uh2qD0Cv-ijB1QdXFDA4GyZQ&amp;h=AT0xdEQwOz3FuZkIU4_Cggt5gH40NkrBy0Qj5opgOyKQOkps3zFF9Jaap6kO0ix32ywxVjNaBynC-LhsB0vCWVxWtObIpKLKhGvpum2Vu4MTJtIGQQZ2l1aKD_E&amp;__tn__=%2CmH-R&amp;c[0]=AT0R_oX6ox2zXBFV9-eD4m35qlofJXbYyFcnjtpefNgxNcfn83dxj_jT1ipTJ7OQtata2N-nVyoWrfRX137BZ1S6aENXWJxblUR1MW4puY0P4bcYuZVY7vWSLZoA7RMojBEfy-DKYsElFTIo3HCPp5gXcBAzj6d4h749RwS9nYD2bSc8KhPSCbPMUOuM_42tqw"
                                                                                        rel="nofollow noopener" role="link"
                                                                                        tabIndex="0" target="_blank">
                                                                                        <div
                                                                                            className="b3i9ofy5 s1tcr66n l9j0dhe7 p8dawk7l">
                                                                                            <div
                                                                                                className="thin_ad_border_length rq0escxv l9j0dhe7 du4w35lb j83agx80 pfnyh3mw i1fnvgqd bp9cbjyn owycx6da btwxx1t3 b5q2rw42 lq239pai f10w8fjw hv4rvrfc dati1w0a pybr56ya">
                                                                                                <div
                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb d2edcug0 hpfvmrgz rj1gh0hx buofh1pr g5gj957u p8fzw8mz pcp91wgn">
                                                                                                    <div
                                                                                                        className="bi6gxh9e sqxagodl">
                                                                                                <span
                                                                                                    className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 tia6h79c fe6kdd0r mau55g9w c8b282yb iv3no6db e9vueds3 j5wam9gi knj5qynh m9osqain"
                                                                                                    dir="auto"><span
                                                                                                    className="a8c37x1j ni8dbmo4 stjgntxs l9j0dhe7 ltmttdrg g0qnabr5 ad_url">{display_link}</span></span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="enqfppq2 muag1w35 ni8dbmo4 stjgntxs e5nlhep0 ecm0bbzt rq0escxv a5q79mjw r9c01rrb">
                                                                                                        <div
                                                                                                            className="j83agx80 cbu4d94t ew0dbk1b irj2b8pg">
                                                                                                            <div
                                                                                                                className="qzhwtbm6 knvmm38d">
                                                                                                        <span
                                                                                                            className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 tia6h79c fe6kdd0r mau55g9w c8b282yb iv3no6db a5q79mjw g1cxx5fr lrazzd5p oo9gr5id hzawbc8m"
                                                                                                            dir="auto"><span
                                                                                                            className="a8c37x1j ni8dbmo4 stjgntxs l9j0dhe7"
                                                                                                            style={{WebkitBoxOrient: "vertical", WebkitLineClamp: "2", display: "-webkit-box"}}><span
                                                                                                            dir="auto">{title}</span></span></span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="qzhwtbm6 knvmm38d">
                                                                                                        <span
                                                                                                            className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v knj5qynh m9osqain hzawbc8m"
                                                                                                            dir="auto"><span
                                                                                                            className="a8c37x1j ni8dbmo4 stjgntxs l9j0dhe7 ltmttdrg g0qnabr5 ad_secondary_description"><span>{link_description}</span></span></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz p8fzw8mz pcp91wgn">
                                                                                                    <div
                                                                                                        className="j83agx80 cbu4d94t taijpn5t up7ckamt">
                                                                                                        <div
                                                                                                            className="tkr6xdv7">
                                                                                                            <object
                                                                                                                type="nested/pressable">
                                                                                                                <a aria-label="Learn More"
                                                                                                                   className="ad_cta oajrlxb2 tdjehn4e gcieejh5 bn081pho humdl8nn izx4hr6d rq0escxv nhd2j8a9 j83agx80 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys d1544ag0 qt6c0cv9 tw6a2znq i1ao9s8h esuyzwwr f1sip0of lzcic4wl l9j0dhe7 abiwlrkh p8dawk7l beltcj47 p86d2i9g aot14ch1 kzx2olss cbu4d94t taijpn5t ni8dbmo4 stjgntxs k4urcfbm tv7at329"
                                                                                                                   href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.crownpavilions.com%2Fgarden-room-range%2F%3Futm_source%3D%257B%257Bsite_source_name%257D%257D%26utm_medium%3Dcpc%26utm_campaign%3D%257B%257Bcampaign.name%257D%257D%26utm_content%3D%257B%257Bad.id%257D%257D%26fbclid%3DIwAR0wDRFNlLiXuD3WDXBkO9ZZk84o-plHusHAHmmJHcCoW3V9uHgcQLAbxoU&amp;h=AT0xdEQwOz3FuZkIU4_Cggt5gH40NkrBy0Qj5opgOyKQOkps3zFF9Jaap6kO0ix32ywxVjNaBynC-LhsB0vCWVxWtObIpKLKhGvpum2Vu4MTJtIGQQZ2l1aKD_E&amp;__tn__=*J%2CmH-R&amp;c[0]=AT0R_oX6ox2zXBFV9-eD4m35qlofJXbYyFcnjtpefNgxNcfn83dxj_jT1ipTJ7OQtata2N-nVyoWrfRX137BZ1S6aENXWJxblUR1MW4puY0P4bcYuZVY7vWSLZoA7RMojBEfy-DKYsElFTIo3HCPp5gXcBAzj6d4h749RwS9nYD2bSc8KhPSCbPMUOuM_42tqw"
                                                                                                                   rel="nofollow noopener"
                                                                                                                   role="link"
                                                                                                                   tabIndex="0"
                                                                                                                   target="_blank">
                                                                                                                    <div
                                                                                                                        className="rq0escxv l9j0dhe7 du4w35lb j83agx80 pfnyh3mw taijpn5t bp9cbjyn owycx6da btwxx1t3 c4xchbtz by2jbhx6">
                                                                                                                        <div
                                                                                                                            className="rq0escxv l9j0dhe7 du4w35lb d2edcug0 hpfvmrgz j83agx80 j5wkysh0 hytbnt81">
                                                                                                                    <span
                                                                                                                        className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v lrazzd5p oo9gr5id"
                                                                                                                        dir="auto"><span
                                                                                                                        className="a8c37x1j ni8dbmo4 stjgntxs l9j0dhe7 ltmttdrg g0qnabr5">{call_to_action}</span></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="n00je7tq arfg74bv qs9ysxi8 k77z8yql i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                                                        data-visualcompletion="ignore"></div>
                                                                                                                </a>
                                                                                                            </object>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div
                                                                                className="stjgntxs ni8dbmo4 l82x9zwi uo3d90p7 h905i5nu monazrh9"
                                                                                data-visualcompletion="ignore-dynamic">
                                                                                <div>
                                                                                    <div>
                                                                                        <div>
                                                                                            {/*this code generates the blue like symbol and the number of likes*/}
                                                                                            {/*<div className="l9j0dhe7">*/}
                                                                                            {/*    <div*/}
                                                                                            {/*        className="thin_ad_border_length bp9cbjyn m9osqain j83agx80 jq4qci2q bkfpd7mw a3bd9o3v kvgmc6g5 wkznzc2l oygrvhab dhix69tm jktsbyx5 rz4wbd8a osnr6wyh a8nywdso s1tcr66n">*/}
                                                                                            {/*        <div*/}
                                                                                            {/*            className="bp9cbjyn j83agx80 buofh1pr ni8dbmo4 stjgntxs">*/}
                                                                                            {/*    <span*/}
                                                                                            {/*        aria-label="See who reacted to this"*/}
                                                                                            {/*        role="toolbar"><span*/}
                                                                                            {/*        className="bp9cbjyn j83agx80 b3onmgus"*/}
                                                                                            {/*        id="jsc_c_h"><span*/}
                                                                                            {/*        className="np69z8it et4y5ytx j7g94pet b74d5cxt qw6c0r16 kb8x4rkr ed597pkb omcyoz59 goun2846 ccm00jje s44p3ltw mk2mc5f4 qxh1up0x qtyiw8t4 tpcyxxvw k0bpgpbk hm271qws rl04r1d5 l9j0dhe7 ov9facns kavbgo14"><span*/}
                                                                                            {/*        className="t0qjyqq4 jos75b7i j6sty90h kv0toi1t q9uorilb hm271qws ov9facns"><span*/}
                                                                                            {/*        className="tojvnm2t a6sixzi8 abs2jz4q a8s20v7p t1p8iaqh k5wvi7nf q3lfd5jv pk4s997a bipmatt0 cebpdrjk qowsmv63 owwhemhu dp1hu0rb dhp61c6y iyyx5f41"><div*/}
                                                                                            {/*        aria-label="Like: 2 people"*/}
                                                                                            {/*        className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 nc684nl6 p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl l9j0dhe7 abiwlrkh p8dawk7l"*/}
                                                                                            {/*        role="button"*/}
                                                                                            {/*        tabIndex="0"><img*/}
                                                                                            {/*        className="j1lvzwm4"*/}
                                                                                            {/*        height="18"*/}
                                                                                            {/*        src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='0%25' y2='100%25'%3e%3cstop offset='0%25' stop-color='%2318AFFF'/%3e%3cstop offset='100%25' stop-color='%230062DF'/%3e%3c/linearGradient%3e%3cfilter id='c' width='118.8%25' height='118.8%25' x='-9.4%25' y='-9.4%25' filterUnits='objectBoundingBox'%3e%3cfeGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/%3e%3cfeOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/%3e%3cfeComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/%3e%3cfeColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/%3e%3c/filter%3e%3cpath id='b' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/%3e%3c/defs%3e%3cg fill='none'%3e%3cuse fill='url(%23a)' xlink:href='%23b'/%3e%3cuse fill='black' filter='url(%23c)' xlink:href='%23b'/%3e%3cpath fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/%3e%3c/g%3e%3c/svg%3e"*/}
                                                                                            {/*        width="18"/></div></span></span></span></span></span>*/}
                                                                                            {/*            <div*/}
                                                                                            {/*                className=""><span*/}
                                                                                            {/*                className="tojvnm2t a6sixzi8 abs2jz4q a8s20v7p t1p8iaqh k5wvi7nf q3lfd5jv pk4s997a bipmatt0 cebpdrjk qowsmv63 owwhemhu dp1hu0rb dhp61c6y iyyx5f41"><div*/}
                                                                                            {/*                className="oajrlxb2 g5ia77u1 qu0x051f esr5mh6w e9989ue4 r7d6kgcz rq0escxv nhd2j8a9 a8c37x1j p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso i1ao9s8h esuyzwwr f1sip0of lzcic4wl l9j0dhe7 abiwlrkh p8dawk7l gmql0nx0 ce9h75a5 ni8dbmo4 stjgntxs"*/}
                                                                                            {/*                role="button"*/}
                                                                                            {/*                tabIndex="0"><span*/}
                                                                                            {/*                aria-hidden="true"*/}
                                                                                            {/*                className="bzsjyuwj ni8dbmo4 stjgntxs ltmttdrg gjzvkazv"><span><span*/}
                                                                                            {/*                className="gpro0wi8 pcp91wgn">2</span></span></span> <span*/}
                                                                                            {/*                className="gpro0wi8 cwj9ozl2 bzsjyuwj ja2t1vim"><span><span*/}
                                                                                            {/*                className="pcp91wgn">2</span></span></span></div></span>*/}
                                                                                            {/*            </div>*/}
                                                                                            {/*        </div>*/}
                                                                                            {/*        <div*/}
                                                                                            {/*            className="kb5gq1qc pfnyh3mw c0wkt4kp"></div>*/}
                                                                                            {/*        <div*/}
                                                                                            {/*            className="bp9cbjyn j83agx80 pfnyh3mw p1ueia1e"></div>*/}
                                                                                            {/*    </div>*/}
                                                                                            {/*</div>*/}
                                                                                            <div
                                                                                                className="ozuftl9m tvfksri0">
                                                                                                <div
                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 pfnyh3mw i1fnvgqd gs1a9yip owycx6da btwxx1t3 ph5uu5jm b3onmgus e5nlhep0 ecm0bbzt nkwizq5d roh60bw9 mysgfdmx hddg9phg">
                                                                                                    <div
                                                                                                        className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t g5gj957u d2edcug0 hpfvmrgz rj1gh0hx buofh1pr n8tt0mok hyh9befq iuny7tx3 ipjc6fyt">
                                                                                                        <div
                                                                                                            aria-label="Like"
                                                                                                            className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 pq6dq46d mg4g778l btwxx1t3 pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb lzcic4wl abiwlrkh p8dawk7l"
                                                                                                            role="button"
                                                                                                            tabIndex="0">
                                                                                                            <div
                                                                                                                className="rq0escxv l9j0dhe7 du4w35lb j83agx80 g5gj957u rj1gh0hx buofh1pr hpfvmrgz taijpn5t bp9cbjyn owycx6da btwxx1t3 d1544ag0 tw6a2znq jb3vyjys dlv3wnog rl04r1d5 mysgfdmx hddg9phg qu8okrzs">
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                            <span
                                                                                                                className="pq6dq46d"><i
                                                                                                                className="hu5pjgll m6k467ps sp_Md36BmaRPC2 sx_d796e8"></i></span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                            <span
                                                                                                                className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v lrazzd5p m9osqain"
                                                                                                                dir="auto"><span>Like</span></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="n00je7tq arfg74bv qs9ysxi8 k77z8yql i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                                                data-visualcompletion="ignore"
                                                                                                                style={{borderRadius: "4px"}}></div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            aria-label="React"
                                                                                                            className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 pq6dq46d mg4g778l btwxx1t3 pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz b4ylihy8 rz4wbd8a b40mr0ww a8nywdso pmk7jnqg i1ao9s8h esuyzwwr f1sip0of du4w35lb lzcic4wl abiwlrkh p8dawk7l pphx12oy hmalg0qr q45zohi1 g0aa4cga"
                                                                                                            role="button"
                                                                                                            tabIndex="0"><i
                                                                                                            className="hu5pjgll m6k467ps sp_3ml9p-EoR8n sx_915af0"></i>
                                                                                                            <div
                                                                                                                className="n00je7tq arfg74bv qs9ysxi8 k77z8yql i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                                                data-visualcompletion="ignore"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t g5gj957u d2edcug0 hpfvmrgz rj1gh0hx buofh1pr n8tt0mok hyh9befq iuny7tx3 ipjc6fyt">
                                                                                                        <div
                                                                                                            aria-label="Leave a comment"
                                                                                                            className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 pq6dq46d mg4g778l btwxx1t3 pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb lzcic4wl abiwlrkh p8dawk7l"
                                                                                                            role="button"
                                                                                                            tabIndex="0">
                                                                                                            <div
                                                                                                                className="rq0escxv l9j0dhe7 du4w35lb j83agx80 g5gj957u rj1gh0hx buofh1pr hpfvmrgz taijpn5t bp9cbjyn owycx6da btwxx1t3 d1544ag0 tw6a2znq jb3vyjys dlv3wnog rl04r1d5 mysgfdmx hddg9phg qu8okrzs">
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                                    <i className="hu5pjgll m6k467ps sp_Md36BmaRPC2 sx_f899a3"></i>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                            <span
                                                                                                                className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v lrazzd5p m9osqain"
                                                                                                                dir="auto">Comment</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="n00je7tq arfg74bv qs9ysxi8 k77z8yql i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                                                data-visualcompletion="ignore"
                                                                                                                style={{borderRadius: "4px"}}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t g5gj957u d2edcug0 hpfvmrgz rj1gh0hx buofh1pr n8tt0mok hyh9befq iuny7tx3 ipjc6fyt">
                                                                                                        <div
                                                                                                            aria-label="Send this to friends or post it on your timeline."
                                                                                                            className="oajrlxb2 gs1a9yip g5ia77u1 mtkw9kbi tlpljxtp qensuy8j ppp5ayq2 goun2846 ccm00jje s44p3ltw mk2mc5f4 rt8b4zig n8ej3o3l agehan2d sk4xxmp2 rq0escxv nhd2j8a9 pq6dq46d mg4g778l btwxx1t3 pfnyh3mw p7hjln8o kvgmc6g5 cxmmr5t8 oygrvhab hcukyx3x tgvbjcpo hpfvmrgz jb3vyjys rz4wbd8a qt6c0cv9 a8nywdso l9j0dhe7 i1ao9s8h esuyzwwr f1sip0of du4w35lb lzcic4wl abiwlrkh p8dawk7l"
                                                                                                            role="button"
                                                                                                            tabIndex="0">
                                                                                                            <div
                                                                                                                className="rq0escxv l9j0dhe7 du4w35lb j83agx80 g5gj957u rj1gh0hx buofh1pr hpfvmrgz taijpn5t bp9cbjyn owycx6da btwxx1t3 d1544ag0 tw6a2znq jb3vyjys dlv3wnog rl04r1d5 mysgfdmx hddg9phg qu8okrzs">
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                                    <i className="hu5pjgll m6k467ps sp__rMRFx0rtRp sx_e8ba25"></i>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="rq0escxv l9j0dhe7 du4w35lb j83agx80 cbu4d94t pfnyh3mw d2edcug0 hpfvmrgz ph5uu5jm b3onmgus iuny7tx3 ipjc6fyt">
                                                                                                            <span
                                                                                                                className="d2edcug0 hpfvmrgz qv66sw1b c1et5uql gk29lw5a a8c37x1j keod5gw0 nxhoafnm aigsh9s9 d9wwppkn fe6kdd0r mau55g9w c8b282yb hrzyx87i jq4qci2q a3bd9o3v lrazzd5p m9osqain"
                                                                                                                dir="auto">Share</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="n00je7tq arfg74bv qs9ysxi8 k77z8yql i09qtzwb n7fi1qx3 b5wmifdl hzruof5a pmk7jnqg j9ispegn kr520xx4 c5ndavph art1omkt ot9fgl3s"
                                                                                                                data-visualcompletion="ignore"
                                                                                                                style={{borderRadius:"4px"}}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        }
