import React from 'react';


export function truncateText(text, char_num) {
    return text.slice(0, char_num)
}

export function characterCount(text) {
    return text.length

}


export function FacebookTruncatedTextField(props) {

    const {full_text} = props
    const {truncate_at_char_num} = props
    const {text_for_toggle} = props
    const [toggled, set_toggle_state] = React.useState(false)

    if (full_text == undefined) {
        return <div data-testid={"no_text_provided"}/>
    }

    //if See More has been toggled to yes
    if (toggled === true) {
        console.log(full_text)
        return <span>{full_text}</span>
    }

    if (characterCount(full_text) <= truncate_at_char_num) {
        return (<span>{full_text}</span>)
    } else {
        const visible_text = truncateText(full_text, truncate_at_char_num)
        return <span>{visible_text}<a onClick={() => {
            set_toggle_state(true)
        }} data-testid={"see_more"} style={{color: "#576b95"}}>{text_for_toggle}</a></span>
    }
}

