import {useLocation} from "react-router";
import {Redirect, useHistory} from "react-router-dom";
import React from "react";
import {useFormik} from "formik";
import {useAuth0} from "@auth0/auth0-react";
import {Col, Container, FormGroup, Row, Spinner} from "react-bootstrap";
import background_image from "../imgs/grey-gb-1280.png";


export function extractCampaignIDFromLocation(url_str) {
    //expects url to match this format '/view_campaign/35'
    let split_string = url_str.split('/')
    if (split_string.length >= 5) {
        return undefined
    }

    if (split_string[1] != "campaigns") {
        return undefined
    }

    if (split_string[2] == "") {
        return undefined
    }

    if (split_string[3] != "invite_client") {
        return undefined
    }

    let campaign_id = split_string[2]

    return campaign_id
}

export default function ClientInviteCampaignIDFetcher() {
    const location = useLocation()
    const campaign_id = extractCampaignIDFromLocation(location.pathname)

    if (campaign_id) {
        return (<ClientInvitePageContainer campaign_id={campaign_id}/>)
    } else {
        return <Redirect to='/error'/>
    }


}

//Simple formik form with email validation
//Async on submit
//set submit button text to submitting
//On completion
//create alert - Your Invitation Has Been Sent
export function ClientInvitePageContainer(props) {
    const campaign_id = props.campaign_id
    return (
        <Container>
            <Col>
                <h4 className={"branded_h4"}>Invite Client To View Template Variations</h4>
                <FetchCampaignDetailsAsync campaign_id={campaign_id}></FetchCampaignDetailsAsync>
            </Col>
        </Container>
    )
}

export function FetchCampaignDetailsAsync(props) {
    const {getAccessTokenSilently} = useAuth0();
    //component state set
    const campaign_id = props.campaign_id
    const [campaigns, setCampaigns] = React.useState()
    //use effect runs once on component mount
    React.useEffect(() => {

        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })
                console.log(campaign_id)
                const response = await fetch(`/api/v1/resources/campaigns/${campaign_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }

                    });
                if (response.ok) {
                    const responseData = await response.json();
                    setCampaigns({'Status': 'Complete', 'Results': responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setCampaigns({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occurred Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setCampaigns({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }
            //return <CampaignContainer data={campaigns}/>
        };
        callSecureApi();
    }, []);

    //inital result which is returned -- can be returned before the async effect hook is finished
    //initial result will always equal value passed to useState on mounting
    //needs to be tied to state hook to drive re-render
    //output of async function needs to be handled
    //if we have valid campaigns then pass to campaign container

    if (campaigns === undefined) {
        return (<div>
            Loading Campaigns
        </div>)
    } else if (campaigns.Status === "Complete") {

        return (<div fluid className="mt-5">
            <ClientInviteForm props={campaigns}/>
        </div>)
    } else if (campaigns.Status === "Error") {
        throw JSON.stringify(campaigns.Results)
    } else {
        return (<div></div>)
    }

}

function validate(values) {

    const errors = {};
    if (!values.client_email) {
        errors.client_email = "Required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.client_email)) {
        errors.client_email = 'Invalid email address';
    }
    return errors
};


function ClientInviteForm(props) {

    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const campaign_object = props.props.Results
    const campaign_id = campaign_object.id
    const [request_state, setRequestState] = React.useState("Submit")

    const formik = useFormik({
        initialValues: {
            client_email: '',
        }, validate,
        onSubmit: async (values) => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })

                setRequestState("Submitting")

                const response = await fetch('/api/v1/resources/invites/client_invite', {
                    method: 'POST',
                    body: JSON.stringify({
                        'invitee_email': formik.values.client_email,
                        'campaign_id': campaign_id,
                        'redirect_url': campaign_object.ad_preview_link
                    }),
                    headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`}
                })

                if (!response.ok) {

                    if (response.status === 422) {
                        setRequestState("Submit")
                        const results = await response.json()
                        formik.errors.client_email = `${results.message}`
                    } else {
                        setRequestState({
                            'Status': 'Error',
                            'Results': {'message': `API Error Occurred Sending The Client Invite: ${response.status} : ${response.statusText}`}
                        })
                    }

                } else {
                    const results = await response.json()
                    const client_account_id = results.client_account_id
                    alert(`An Invite Has Been Sent To ${formik.values.client_email}`)
                    setRequestState("Submit")
                }

            } catch (err) {
                setRequestState({
                    'Status': 'Error',
                    'Results': {'message': err}
                })
            }
        }
    });

    if (request_state.Status === "Error") {
        throw JSON.stringify(request_state.Results)
    }

    return (

        <div style={{
            backgroundImage: `url(${background_image})`,
            height: '720px',
            width: '100%',
            backgroundColor: ""
        }}>
            <Container>
                <form onSubmit={formik.handleSubmit}>
                    <FormGroup>
                        <label htmlFor="client_email" className={"branded_text"}>Client Email</label>
                        <input
                            className={"branded_text"}
                            id='client_email'
                            name='client_email'
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.client_email}
                            onBlur={formik.handleBlur}
                        />
                    </FormGroup>
                    {formik.errors.client_email && formik.touched.client_email ?
                        <div className={"branded_text"}
                             style={{"color": "red"}}>{formik.errors.client_email}</div> : null}

                    <SubmitWithLoader state={request_state}></SubmitWithLoader>
                </form>
            </Container>
        </div>
    );


}

function SubmitWithLoader(props) {
    const state = props.state

    if (state == "Submitting") {
        return (
            <div className={"branded_text"}>
                <Spinner animation={"border"}/>
                <div>Sending Emails</div>
            </div>

        )
    } else {
        return (<button className={"btn branded_primary_button"} type="submit">Submit</button>)
    }
}