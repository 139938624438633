import React, {useEffect, useState} from 'react'
import {useRef} from 'react'
import Container from "react-bootstrap/Container";
import {useFormik} from "formik";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router";
import {Redirect} from 'react-router-dom'
import {Col, Row, Table} from "react-bootstrap";
import background_image from "../imgs/grey-gb-1280.png";


//a very hacky way of doing this
//one function and different regex patterns might be more reuseable?
export function extractCampaignIDFromLocation(url_str) {
    //expects url to match this format '/view_campaign/35'
    let split_string = url_str.split('/')
    if (split_string.length >= 4) {
        return undefined
    }

    if (split_string[1] != "view_campaign") {
        return undefined
    }

    if (split_string[2] == "") {
        return undefined
    }

    let campaign_id = split_string[2]

    return campaign_id
}

export function CampaignIDFetcher() {
    const location = useLocation()
    const campaign_id = extractCampaignIDFromLocation(location.pathname)

    if (campaign_id) {
        return (CampaignContentsContainer({"id": campaign_id}))
    } else {
        return <Redirect to='/error'/>
    }


}

export function CampaignContentsContainer(campaign_id) {
    //campaign_id is fetched from url?

    return (

        <div style={{
            backgroundImage: `url(${background_image})`,
            height: '720px',
            width: '100%',
            backgroundColor: ""
        }}>
            <Container>
                <div className="row mt-2"><FetchCampaignDetailsAsync props={campaign_id}/></div>
                <div className="row mt-5"><TemplateVariationContainer props={campaign_id}/></div>
            </Container>
        </div>

    )
}


export function FetchCampaignDetailsAsync(props) {
    const {getAccessTokenSilently} = useAuth0();
    //component state set
    const campaign_id = props.props.id
    const [campaigns, setCampaigns] = React.useState()
    //use effect runs once on component mount
    React.useEffect(() => {

        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })
                console.log(campaign_id)
                const response = await fetch(`/api/v1/resources/campaigns/${campaign_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }

                    });

                if (response.ok) {
                    const responseData = await response.json();
                    setCampaigns({'Status': 'Complete', 'Results': responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setCampaigns({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setCampaigns({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }
            //return <CampaignContainer data={campaigns}/>
        };
        callSecureApi();
    }, []);

    //inital result which is returned -- can be returned before the async effect hook is finished
    //initial result will always equal value passed to useState on mounting
    //needs to be tied to state hook to drive re-render
    //output of async function needs to be handled
    //if we have valid campaigns then pass to campaign container

    if (campaigns === undefined) {
        return (<div className={"branded_text"}>
            Loading Campaigns
        </div>)
    } else if (campaigns.Status === "Complete") {

        return (<div fluid className="mt-5">
            <CampaignDetailsContainer props={campaigns}/>
        </div>)
    } else if (campaigns.Status === "Error") {
        throw JSON.stringify(campaigns.Results)

    } else {
        return (<div></div>)
    }

}

function CampaignDetailsContainer(campaign_data_model) {

    const campaign_details = campaign_data_model.props.Results
    const [campaign_password, set_campaign_password] = React.useState(campaign_details.ad_preview_password)
    const [campaign_name, set_campaign_name] = React.useState(campaign_details.campaign_name)
    const campaign_url = campaign_details.ad_preview_link

    if (campaign_url == "") {
        return (<Container>
            <Row>{CampaignNameBar(campaign_name, set_campaign_name, campaign_details)}</Row>
            <Row>{InviteClientButton(campaign_details)}</Row>
            <Row className="mt-1">{InviteUserButton(campaign_details)}</Row>
        </Container>)

    } else {
        return (<Container>
            <Row>{CampaignNameBar(campaign_name, set_campaign_name, campaign_details)}</Row>
            <Row>{CampaignUrlBox(campaign_url)}</Row>
            <Row>{InviteClientButton(campaign_details)}</Row>
            <Row className="mt-1">{InviteUserButton(campaign_details)}</Row>
        </Container>)
    }

}


function TemplateVariationContainer(props) {
    const campaign_id = props.props.id
    const [template_variations, setTemplateVariations] = React.useState()

    const {getAccessTokenSilently} = useAuth0();
    React.useEffect(() => {

        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })

                const response = await fetch(`/api/v1/resources/template_variations?campaign_id=${campaign_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }

                    });


                if (response.ok) {
                    const responseData = await response.json();
                    setTemplateVariations({'Status': 'Complete', 'Results': responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setTemplateVariations({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setTemplateVariations({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }

        };
        callSecureApi();
    }, []);

    if (template_variations === undefined) {
        return (<div className={"branded_text"}>Loading Template Variations</div>)

    } else if (template_variations.Status === "Complete") {
        return (<GroupOfTemplateRows campaign_id={campaign_id} template_variations={template_variations}/>)


    } else if (template_variations.Status === "Error") {
        throw JSON.stringify(template_variations.Results)
    } else {
        return (<div></div>)
    }

}

function GroupOfTemplateRows(props) {
    const data_model = props.template_variations.Results
    const campaign_id = props.campaign_id

    const template_variation_rows = data_model.map((template_variation) =>
        <tr style={{"vertical-align": "middle"}} data-testid="Template_Variation_Row"
            key={template_variation.template_variation_id}>{
            TemplateVariationRow(template_variation)
        }</tr>)

    return (<Container>
        <div className="row mb-5"><CreateNewTemplateVariation props={{'campaign_id': campaign_id}}/></div>
        <Table>
            <thead>
            <th className={"text-left branded_table_title"}>Template Variation</th>
            <th className={"text-center branded_table_title"}>Create Date</th>
            <th className={"text-center branded_table_title"}>Status</th>
            <th className={"text-center branded_table_title"}>Actions</th>
            </thead>
            <tbody>{template_variation_rows}</tbody>
        </Table>
    </Container>)
}

export function TemplateVariationCreateDate(props) {

    const {datetime_str} = props
    const create_datetime = Date.parse(datetime_str)

    if (Number.isNaN(create_datetime)) {
        return <div data-testid={"unparseable_date"}></div>
    }

    const create_datestamp = new Date(create_datetime).toDateString()
    return (<div className={"branded_text"}>{create_datestamp}</div>)
}

function TemplateVariationRow(template_variation_row) {

    const [template_variation_values, setValues] = React.useState(template_variation_row)
    const [bulksheet_url_result, setBulksheetUrl] = useState("")
    //all states are set here before any conditional logic to prevent "rendered fewer hooks then expected react error"
    const {getAccessTokenSilently} = useAuth0()
    useEffect(() => {
        callGetBulksheetEndpoint(template_variation_values.id, getAccessTokenSilently, setBulksheetUrl)
    }, [])


    if (template_variation_values != undefined) {
        return (<>
                <td style={{

                    "max-width":"200px",
                    "overflow": "wrap",
                    "text-overflow": "ellipsis"
                }}>
                    {ViewTemplateVariationButton(template_variation_values)}
                </td>

                <td align={"center"}>
                    <TemplateVariationCreateDate datetime_str={template_variation_values.create_datetime}></TemplateVariationCreateDate>
                </td>

                <td align={"center"}>
                    {TemplateVariationStatusBox(template_variation_values)}
                </td>
                <td>
                    <Row> <Col align={"right"}><DownloadBulksheetButton
                        template_variation_values={template_variation_values}
                        bulksheet_url={bulksheet_url_result.Results}
                        setBulksheetUrl={setBulksheetUrl}></DownloadBulksheetButton></Col>

                        <Col align={"left"}>{DeleteTemplateVariationButton(template_variation_values, setValues)}</Col>

                    </Row>

                </td>
            </>
        )
    }
}

function CampaignNameBar(campaign_name, set_name, campaign_data_model) {

    const {getAccessTokenSilently} = useAuth0()
    const campaign_id = campaign_data_model.id
    const [button_state, set_button_state] = React.useState("Submit")
    const [submission_state, set_submission_state] = React.useState({'Status': '', 'Results': ''})

    async function change_campaign_name() {
        const token = await getAccessTokenSilently({
            audience: "https:/creative_approvals_tool/api",
            scope: "read:current_user"
        })


        const response = await fetch(`/api/v1/resources/campaigns/${campaign_id}`, {
            method: 'PATCH',
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
            body: JSON.stringify({'campaign_name': formik.values.campaign_name}),
        })

        if (!response.ok) {
            set_submission_state({
                'Status': 'Error',
                'Results': {'message': `API Error Renaming Campaigns: ${response.status} : ${response.statusText}`}
            })
        } else {
            const results = await response.json()
            const new_campaign_name = results.campaign.campaign_name
            set_name(new_campaign_name)
            set_button_state("Submitted")
        }


    }

    const formik = useFormik({
        initialValues: {'campaign_name': campaign_name}, onSubmit: change_campaign_name
    })

    if (submission_state['Status'] === 'Error') {
        throw JSON.stringify(submission_state.Results)
    }

    return (
        <div data-testid='Campaign_Name_Bar'>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="password" className={"branded_text"}>Campaign Name: </label>
                <input
                    id='campaign_name'
                    name='campaign_name'
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.campaign_name}
                    onBlur={formik.handleBlur}
                />
                <button className="btn branded_secondary_button" type="submit">{button_state}</button>
            </form>

        </div>
    )
}


function CampaignUrlBox(campaign_url, set_password) {

    const textAreaRef = useRef(null);
    const [copyButtonState, setButtonState] = React.useState("Copy")

    const copyCodeToClipboard = () => {
        const range = document.createRange();
        range.selectNode(textAreaRef.current);
        window.getSelection().addRange(range);
        try {
            // Now that we've selected the anchor text, execute the copy command
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copy email command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        // Remove the selections - NOTE: Should use
        // removeRange(range) when it is supported
        window.getSelection().removeAllRanges();


    }

    return (

        <div data-testid='Campaign_Url_Bar' className="ml-1 row">
            <div className={"branded_text"}>Ad Preview URL:</div>
            <div className={"branded_text"} style={{color: "darkblue"}} ref={textAreaRef}>{`${campaign_url}`}</div>
            <div className>
                <button className="btn branded_secondary_button ml-5" type="button>"
                        onClick={copyCodeToClipboard}>{copyButtonState}
                </button>
            </div>
        </div>

    )
}


function InviteClientButton(props) {
    const campaign_id = props.id
    return (<div>
        <a href={`/campaigns/${campaign_id}/invite_client`}
           className="btn  branded_primary_button" role="button">Invite Client To View Templates</a>
    </div>)
}

function InviteUserButton(props) {
    const campaign_id = props.id
    return (<div>
        <a href={`/campaigns/${campaign_id}/invite_user`}
           className="btn  branded_primary_button" role="button">Invite User To View Campaign</a>
    </div>)
}


function CreateNewTemplateVariation(props) {
    const campaign_id = props.props.campaign_id
    return (<div>
        <a href={`/campaigns/${campaign_id}/create_new_template_variation`}
           className="btn branded_primary_button" role="button">Create New Template Variation</a>
    </div>)
}


async function callGetBulksheetEndpoint(template_variation_id, getAccessTokenSilently, setBulksheetUrl) {
    const token = await getAccessTokenSilently({
        audience: "https:/creative_approvals_tool/api",
        scope: "read:current_user"
    })


    const response = await fetch(`/api/v1/resources/template_variations/${template_variation_id}/bulksheet`, {
        method: 'GET',
        headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`}
    })

    if (!response.ok) {
        const err_message = `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`
        setBulksheetUrl({'Status': 'Error', 'Results': err_message})
    }

    const results = await response.json()
    const url = results.bulksheet_download_url
    setBulksheetUrl({'Status': 'Complete', 'Results': url})
}


function ViewTemplateVariationButton(props) {
    const template_variation_id = props.id
    const template_variation_name = props.template_variation_name

    return (<div>
        <a href={"/view_template_variation/" + template_variation_id}
           className="branded_text" role="button">{template_variation_name}</a>
    </div>)
}

function TemplateVariationStatusBox(props) {
    const template_variation_status = props.template_variation_status
    if (template_variation_status == "Approved") {
        return (<div className="status_box_success ml-1">{template_variation_status}</div>)
    }

    if (template_variation_status == "Awaiting Feedback") {
        return (<div className="status_box_requires_feedback ml-1">{template_variation_status}</div>)

    } else {
        return (<div className="status_box ml-1">{template_variation_status}</div>)
    }

}

function DeleteTemplateVariationButton(template_variation, setTemplateVariationModel) {

    const {getAccessTokenSilently} = useAuth0()
    const template_variation_id = template_variation.id

    async function callDeleteEndpoint(template_variation_id) {

        const token = await getAccessTokenSilently({
            audience: "https:/creative_approvals_tool/api",
            scope: "read:current_user"
        })

        const response = await fetch(`/api/v1/resources/template_variations/${template_variation_id}`, {
            method: 'DELETE',
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`}
        })

        if (!response.ok) {
            const err_message = `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`
            throw err_message
        }
    }

    function delete_template_variation() {
        callDeleteEndpoint(template_variation_id)
        setTemplateVariationModel()
    }

    return (<button className={"btn branded_secondary_button"} onClick={() => {
            if (window.confirm('Are you sure you wish to delete this item?')) delete_template_variation()
        }}>
            Delete
        </button>
    )

}

function DownloadBulksheetButton(props) {
    const bulksheet_url = props.bulksheet_url
    return (<div>
        <a href={bulksheet_url}
           className="btn  branded_primary_button" role="button">Download Bulksheet</a>
    </div>)

}