import React from "react"
import background_image from '../imgs/grey-gb-1280.png'
import {LoginButton, LogoutButton} from "../auth/auth0_login";


export default function LoginPage() {
    const current_origin=window.location.origin
    return (
        <>
            <div style={{
                backgroundImage: `url(${background_image})`,
                height: '720px',
                width: '100%',
                backgroundColor: ""
            }}>
                <div className={"homepage_container"}>

                    <h1 className={"branded_h1"} style={{textAlign: "center"}}>Welcome To Adpaca</h1>

                    <p className={"branded_text"} style={{textAlign: "center"}}>Semetrical's Creative Management Platform</p>
                    <LoginButton redirect_uri={`${current_origin}/view_all_campaigns`}></LoginButton>
                </div>
            </div>
        </>
    )

}

