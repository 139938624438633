import React from 'react';
import {useFormik} from "formik";
import {useAuth0} from "@auth0/auth0-react";
import {Container, Col, Row, Button, FormGroup} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import background_image from "../imgs/grey-gb-1280.png";

function CreateCampaignForm() {

    const {getAccessTokenSilently} = useAuth0()
    const history = useHistory()
    const [request_result, set_request_result] = React.useState("")

     function validate(values) {
        const errors = {};
        if (!values.campaignName) {
            errors.campaignName = "Required"
        }
        return errors
    }

    const formik = useFormik({
        initialValues: {
            campaignName: '',
        },validate,
        onSubmit: async (values) => {
            try {
                ;
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })


                const response = await fetch('/api/v1/resources/campaigns/', {
                    method: 'POST',
                    body: JSON.stringify({'campaign_name': formik.values.campaignName}),
                    headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`}
                })
                if (!response.ok) {
                    set_request_result({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                } else {
                    const results = await response.json()
                    const campaign_id = results['campaign_id']
                    alert("Campaign Created")
                    history.push(`/view_campaign/${campaign_id}`)
                }
            } catch (err) {
                set_request_result({
                    'Status': 'Error',
                    'Results': err
                })
            }
        }
    });

    if (request_result.Status === "Error") {
        throw JSON.stringify(request_result.Results)
    }
    return (
        <div style={{
            backgroundImage: `url(${background_image})`,
            height: '720px',
            width: '100%',
            backgroundColor: ""
        }}>
            <Container>

                <form onSubmit={formik.handleSubmit}>
                    <FormGroup>
                    <label htmlFor="campaignName"className={"branded_text"}>Campaign Name</label>
                    <input
                        id='campaignName'
                        name='campaignName'
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.campaignName}
                        onBlur={formik.handleBlur}
                    />

                        <div style={{color:"red"}}>{formik.errors.campaignName}</div>
                    </FormGroup>
                    <button type={"submit"} className={"btn branded_primary_button"}>Submit</button>
                </form>
            </Container>
        </div>
    );


}


export default function CampaignContainer() {

    return (
        <CreateCampaignForm/>
    )

}