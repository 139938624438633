import React from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {Container, Col, Row, Table} from 'react-bootstrap'
import background_image from "../imgs/grey-gb-1280.png";
import LoadingScreen from "../loading_screen/loading_screen";

export function sortCampaignArrayDesc(campaign_array) {

    return campaign_array.sort((camp_a, camp_b) => {
        let camp_a_date = Date.parse(camp_a.created_at)
        let camp_b_date = Date.parse(camp_b.created_at)

        if (isNaN(camp_a_date)) {
            return 1
        }
        if (isNaN(camp_b_date)) {
            return -1
        }

        let date_result = camp_a_date - camp_b_date

        if (date_result === 0) {
            return 0
        }

        if (date_result < 0) {
            return 1
        }

        if (date_result > 0) {
            return -1
        }
    })
}


export function FetchCampaignsAsync() {
    const {getAccessTokenSilently} = useAuth0();
    //component state set
    const [campaigns, setCampaigns] = React.useState()
    //use effect runs once on component mount


    React.useEffect(() => {

        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })

                const response = await fetch('/api/v1/resources/campaigns',
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                //if 200 then update state
                //otherwise trigger exception for sentry to then handle
                if (response.ok) {
                    const responseData = await response.json();
                    setCampaigns({'Status': 'Complete', 'Results': responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setCampaigns({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setCampaigns({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }
        }
        //return <CampaignContainer data={campaigns}/>
        callSecureApi();
    }, []);

    //inital result which is returned -- can be returned before the async effect hook is finished
    //initial result will always equal value passed to useState on mounting
    //needs to be tied to state hook to drive re-render
    //output of async function needs to be handled
    //if we have valid campaigns then pass to campaign container

    if (campaigns === undefined) {
        return (<div className={"branded_text"}>
            <LoadingScreen loading_text={"Loading Campaigns"}></LoadingScreen>
        </div>)
    } else if (campaigns.Status === "Complete") {

        return (<div fluid className="mt-5">
            <CampaignContainer props={campaigns}/>
        </div>)
    } else if (campaigns.Status === "Error") {
        throw JSON.stringify(campaigns.Results)

    } else {
        return (<div></div>)
    }


}


export function CampaignContainer(campaign_objects) {
    console.log('Data In CampaignContainer', campaign_objects.props)
    let data_model = campaign_objects.props.Results
    data_model = sortCampaignArrayDesc(data_model)


    const campaign_rows = data_model.map((campaign_row) =>
        <tr key={campaign_row.id} data-testid='Campaign_Row'>{
            campaignRow(campaign_row)
        }</tr>)

    return (

        <div style={{
            backgroundImage: `url(${background_image})`,
            height: '720px',
            width: '100%',
            backgroundColor: ""
        }}>
            <Container>
                <Row>{createNewCampaign()}</Row>
                <Table style={{"table-layout": "fixed"}}>
                    <thead>
                    <tr>
                        <th className={"text-left branded_table_title"}>Campaign</th>
                        <th className={"text-center branded_table_title"}>Status</th>
                        <th className={"text-center branded_table_title"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>{campaign_rows}</tbody>
                </Table>
            </Container>
        </div>
    )
}

export function campaignRow(props) {

    const [campaigns, setCampaigns] = React.useState(props)

    if (campaigns != undefined) {
        //if campaigns is not empty
        return (<>
                <td style={{
                    "max-width": "100px",
                    "white-space": "nowrap",
                    "overflow": "clip",
                    "text-overflow": "ellipsis"
                }} className={"text-left"}>
                    {campaignButton(campaigns)}
                </td>
                <td align={"center"}>
                    {campaignStatusBox(campaigns)}
                </td>
                <td align={"center"}>
                    {deleteCampaignButton(campaigns, setCampaigns)}
                </td>
            </>
        )
    }

}

function createNewCampaign() {

    return (<div>
        <a href={"/create_new_campaign"}
           className="btn  branded_primary_button mb-5" role="button">Create New Campaign</a>
    </div>)
}

function deleteCampaignButton(props, setCampaigns) {

    const {getAccessTokenSilently} = useAuth0()
    const campaign_id_str = props.id

    async function callDeleteEndpoint(campaign_id_str) {
        const token = await getAccessTokenSilently({
            audience: "https:/creative_approvals_tool/api",
            scope: "read:current_user"
        })


        const response = await fetch(`/api/v1/resources/campaigns/${campaign_id_str}`, {
            method: 'DELETE',
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`}
        })

        if (!response.ok) {
            const message = `An Error Has occured : ${response.status}`;
            throw new Error(message)
        }

        const results = await response.json()
    }


    ///on
    function deleteCampaign() {
        //calls stateHook for campaign row and sets it to undefined
        console.log(campaign_id_str)
        callDeleteEndpoint(campaign_id_str)
        setCampaigns(undefined)


    }

    return (<button className={"branded_secondary_button"} onClick={() => {
            if (window.confirm('Are you sure you wish to delete this item?')) deleteCampaign()
        }}>
            Delete
        </button>
    )


}

function campaignButton(props) {
    const campaign_id = props.id
    const campaign_name = props.campaign_name

    return (<div>
        <a href={"/view_campaign/" + campaign_id}
           className="branded_text" role="button">{campaign_name}</a>
    </div>)

}


function campaignStatusBox(props) {
    const campaign_status = props.campaign_status
    if (campaign_status == "Approved") {
        return (<div className="status_box_success branded_text">{campaign_status}</div>)
    }

    if (campaign_status == "Awaiting Feedback") {
        return (<div className="status_box_requires_feedback branded_text">{campaign_status}</div>)

    } else {
        return (<div className="status_box branded_text">{campaign_status}</div>)
    }


}

