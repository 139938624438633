import React from 'react';
import {
    Accordion,
    Button,
    Card,
    Col,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner, Modal, Alert, CloseButton, ListGroup
} from "react-bootstrap";
import brand_logo from "../imgs/semetrical_logo_horizontal.png";
import {Link, Redirect, useHistory} from "react-router-dom";

import Container from "react-bootstrap/Container";
import {useBeforeunload} from "react-beforeunload";

import FacebookSingleImageAdMobile from "../ad_preview_templates/FacebookSingleImageAdMobile";
import FacebookSingleImageAdDesktop from "../ad_preview_templates/FacebookSingleImageAdDesktop"
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router";
import FacebookCarouselAdCardsMobile from "../ad_preview_templates/FacebookCarouselAdCardsMobile";
import InstagramFeedAd from "../ad_preview_templates/InstagramFeedAd";
import InstagramSingleImageStoryAd from "../ad_preview_templates/InstagramSingleImageStoryAd";
import background_image from "../imgs/grey-gb-1280.png";
import LoadingScreen from "../loading_screen/loading_screen";
import InstagramSingleImageFeedAd from "../ad_preview_templates/InstagramSingleImageFeedAd";
import {GoogleDesktopETA, GoogleDesktopRSA, GoogleMobileETA, GoogleMobileRSA} from "../ad_preview_templates/GoogleRSAs";


function setStatusAndFeedbackForAllTemplates(template_variation, status, feedback) {
    template_variation.Results.ad_template_campaigns.forEach(ad_template_campaign => ad_template_campaign.ad_template_groups.forEach(ad_template_group => ad_template_group.ad_templates.forEach(ad_template => {
        ad_template.approval_status.feedback += "\n" + feedback;
        ad_template.approval_status.state = status;
    })))
    return template_variation

}


function setStatusAndOverwriteFeedbackForAllTemplates(template_variation, status, feedback) {
    template_variation.Results.ad_template_campaigns.forEach(ad_template_campaign => ad_template_campaign.ad_template_groups.forEach(ad_template_group => ad_template_group.ad_templates.forEach(ad_template => {
        ad_template.approval_status.feedback = feedback;
        ad_template.approval_status.state = status;
    })))
    return template_variation

}

export function PublishButton(props) {
    const {template_variation} = props
    const {set_template_variation} = props
    const [submission_state, setSubmissionState] = React.useState("un-submitted")

    if (template_variation.Results.template_variation_status == "Draft") {
        return (<PublishTemplateVariationContainer template_variation={template_variation}
                                                   set_template_variation={set_template_variation}
                                                   submission_state={submission_state}
                                                   setSubmissionState={setSubmissionState}></PublishTemplateVariationContainer>)
    }

    if (submission_state === "submitted") {
        return (<div className={"branded_text"}>Template Is Published</div>)
    } else {

        return (<div></div>)
    }


}


function PublishTemplateVariationContainer(props) {
    const {getAccessTokenSilently} = useAuth0();
    const {template_variation} = props
    const {set_template_variation} = props
    const template_variation_id = template_variation.Results.campaign_id
    const [emails_to_share_with, setEmailsToShareWith] = React.useState([])
    const {submission_state} = props
    const {setSubmissionState} = props

    React.useEffect(() => {
        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })

                const response = await fetch(`/api/v1/resources/campaigns/owners/${template_variation_id}`,
                    {
                        headers: {
                            Authorization: `
                             Bearer ${token}`
                        }

                    });
                if (response.ok) {
                    const responseData = await response.json();
                    setEmailsToShareWith({"Status": "Success", Results: responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setEmailsToShareWith({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setEmailsToShareWith({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }
            //return <CampaignContainer data={campaigns}/>
        };
        callSecureApi();
    }, [])

    if (emails_to_share_with.Status === "Error") {
        throw JSON.stringify(emails_to_share_with.Results)
    }

    if (emails_to_share_with.Results === undefined) {
        return (
            <Spinner animation={"border"}/>
        )
    } else {
        return (<PublishTemplateModal submission_state={submission_state} setSubmissionState={setSubmissionState}
                                      campaign_owners={emails_to_share_with.Results}
                                      template_variation={template_variation}
                                      set_template_variation={set_template_variation}></PublishTemplateModal>)
    }

}


function PublishTemplateModal(props) {
    const [show, setShow] = React.useState(false);
    const [comment_value, setCommentValue] = React.useState("")
    const {campaign_owners} = props
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {template_variation} = props
    const {set_template_variation} = props
    const {submission_state} = props
    const {setSubmissionState} = props

    const accounts_list_items = campaign_owners.map((email) => {
        return <li className={"branded_bullet_points text-left"}>{email}</li>
    })

    return (
        <>
            <button className={"branded_primary_button"} onClick={handleShow}>
                Publish
            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Publish Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col className={"text-left"}>
                        <Row className={"page_header"}>
                            <h6 className={"branded_h5"}>These Accounts Will Be Notified</h6>
                        </Row>
                        <Row>
                            <Col>
                                <ul className={"mt-1"}>
                                    {accounts_list_items}
                                </ul>
                            </Col>
                        </Row>
                        <Row className={"page_header mt-5"}>
                            <h6 className={"branded_h5 mb-1"}>Comments To Share</h6>
                        </Row><Row className={"mt-3"}>

                            <textarea cols={46} rows={8} className={"branded_text branded_text_area"}
                                      style={{fontStyle: "italic"}}
                                      onChange={event => {
                                          setCommentValue(event.target.value)
                                      }} value={comment_value}></textarea>
                    </Row>

                    </Col>

                </Modal.Body>

                <Modal.Footer>

                    <PublishTemplateVariationButton submission_state={submission_state}
                                                    setSubmissionState={setSubmissionState}
                                                    template_variation={template_variation}
                                                    set_template_variation={set_template_variation}
                                                    comment_value={comment_value}></PublishTemplateVariationButton>
                    <button className={"branded_secondary_button"} onClick={handleClose}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


async function RejectAllPressed(template_variation, setTemplateVariation, feedback) {
    //copy the array rather than reference so that useState renders on change
    //react useState only checks if memory location has changed not if contents of location have changed
    template_variation = setStatusAndFeedbackForAllTemplates(template_variation, "reject_pressed", feedback)
    setTemplateVariation({...template_variation})
}

async function ApproveAllPressed(template_variation, setTemplateVariation, feedback) {
    //copy the array rather than reference so that useState renders on change
    //react useState only checks if memory location has changed not if contents of location have changed
    //Approve All should overwrite all the feedback present on ad template. This done in order to remove any negative feedback left previously
    //Individually approved ads can't have feedback
    //Approve All Ads can have feedback -- overwriting an approve all with an approve all makes sense
    //Individually reject ads can have feedback - overwriting a reject add with approved all feedback makes sense
    //Reject All ads can have feedback -- overwriting all rejected add feedback with approved all feedback makes sense
    template_variation = setStatusAndOverwriteFeedbackForAllTemplates(template_variation, "marked_for_approval", feedback)
    setTemplateVariation({...template_variation})
}

function ResolveFeedbackStatus(template_variation) {

    for (var camp = 0; camp < template_variation.Results.ad_template_campaigns.length; camp++) {
        let ad_template_campaign = template_variation.Results.ad_template_campaigns[camp]
        for (var group = 0; group < ad_template_campaign.ad_template_groups.length; group++) {
            let ad_template_groups = ad_template_campaign.ad_template_groups[group]
            for (var template = 0; template < ad_template_groups.ad_templates.length; template++) {
                let ad_template = ad_template_groups.ad_templates[template]
                let approval_state = ad_template.approval_status.state
                if (approval_state === "marked_for_approval") {
                    approval_state = "approved"
                }


            }

        }

    }
    return template_variation
}

function extractAllAdTemplateStatus(template_variation) {

    let extracted_ad_templates = template_variation.Results.ad_template_campaigns.flatMap(ad_template_campaign => ad_template_campaign.ad_template_groups.flatMap(ad_template_group => ad_template_group.ad_templates.flatMap(ad_template => ad_template)))
    return extracted_ad_templates
}

async function SubmitTemplateVariation(template_variation, setTemplateVariation, set_submission_state, getAccessTokenSilently, user_object) {


    //for each ad_template
    //make patch request to update_ad_preview_template
    //group subsequent promises
    //When all have resolved succesfully
    set_submission_state("submitting")
    template_variation = ResolveFeedbackStatus(template_variation)
    let request_body = extractAllAdTemplateStatus(template_variation)


    const token = await getAccessTokenSilently({
        audience: "https:/creative_approvals_tool/api",
        scope: "read:current_user"
    })

    request_body.forEach(ad_template => {
        let approval_state = ad_template.approval_status.state
        ad_template.approval_status.approver_email = user_object.email
        if (approval_state === "marked_for_approval") {
            ad_template.approval_status.state = "approved"
        }
        if (approval_state === "reject_pressed") {
            ad_template.approval_status.state = "rejected"
        }
    })


    const response = await fetch('/api/v1/resources/ad_template_variations', {
        method: 'PATCH',
        headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        body: JSON.stringify(request_body),
    })
    if (response.status != 200) {
        setTemplateVariation({
            'Status': 'Error',
            'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
        })
    }
    if (response.ok) {
        alert("Your Feedback Has Been Submitted To Your Agency Team. You will be notified when completed")
        set_submission_state("submitted")
        setTemplateVariation({...template_variation})
    }

}

async function publishTemplateVariation(setSubmissionState, template_variation, getAccessTokenSilently, set_template_variation, user_comment) {
    setSubmissionState("submitting")

    const token = await getAccessTokenSilently({
        audience: "https:/creative_approvals_tool/api",
        scope: "read:current_user"
    })


    const response = await fetch('/api/v1/resources/invites/publish_template', {
        method: 'POST',
        body: JSON.stringify({
            'template_variation_id': template_variation.Results.template_variation_id,
            'publisher_message': user_comment
        }),
        headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},

    })
    if (response.status != 200) {
        set_template_variation({
            'Status': 'Error',
            'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
        })
    }
    if (response.ok) {
        setSubmissionState("submitted")
        let response_json = response.json()
        template_variation.Results.template_variation_status = response_json['template_variation_status']
        set_template_variation({...template_variation})

    }
}

export function PublishTemplateVariationButton(props) {
    const template_variation = props.template_variation
    const set_template_variation = props.set_template_variation
    const {comment_value} = props
    const template_status = template_variation.Results.template_variation_status
    const {submission_state} = props
    const {setSubmissionState} = props
    const {getAccessTokenSilently} = useAuth0();

    if (submission_state == "submitting") {
        return (<Spinner animation={"border"}/>)
    } else {
        return (<button className="branded_primary_button mx-1" onClick={() => {
            publishTemplateVariation(setSubmissionState, template_variation, getAccessTokenSilently, set_template_variation, comment_value)
        }}>Publish Template</button>)
    }


}

export function SubmitFeedbackModal(props) {

    const [show, setShow] = React.useState(false);
    const {template_variation} = props
    const {set_template_variation} = props
    const {set_is_submitted} = props

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (<>
        <button className={'btn branded_primary_button'} onClick={handleShow}>Submit Feedback</button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className={"branded_h4"}>Submit Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Col>
                        <Row><Alert variant={"warning"} style={{"font": "font-family :raleway, sans-serif"}}>Warning:
                            You Cannot Change Your Feedback Once Submitted</Alert></Row>
                    </Col>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <SubmitFeedbackButton template_variation={template_variation}
                                      set_template_variation={set_template_variation}
                                      set_is_submitted={set_is_submitted}></SubmitFeedbackButton>
                <button className="btn btn-secondary ml-2" onClick={handleClose}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    </>)
}

export function SubmitFeedbackButton(props) {

    const template_variation = props.template_variation
    const set_template_variation = props.set_template_variation
    const [submission_state, setSubmissionState] = React.useState("un-submitted")
    const {set_is_submitted} = props

    React.useEffect(() => {
        if (submission_state === "submitted") {
            set_is_submitted(true)
        }
    }, [submission_state])


    const {getAccessTokenSilently} = useAuth0();
    const {user, isAuthenticated, isLoading} = useAuth0()


    if (submission_state == "submitting") {
        return (<Spinner animation={"border"}/>)
    } else {
        return (<button className={"btn btn-warning mx-1"} onClick={() => {
            SubmitTemplateVariation(template_variation, set_template_variation, setSubmissionState, getAccessTokenSilently, user)
        }}>
            Submit Feedback</button>)
    }
}

function hasUnreviewedTemplate(template_variations) {

    let unreviewed_templates_exist = false
    template_variations.Results.ad_template_campaigns.forEach(ad_template_campaign => ad_template_campaign.ad_template_groups.forEach(ad_template_group => ad_template_group.ad_templates.forEach(ad_template => {
            if (ad_template.approval_status.state === "unreviewed") {
                unreviewed_templates_exist = true
            }
        }
    )))

    return unreviewed_templates_exist
}

function ConditionalSubmitFeedbackButton(props) {
    const {template_variation} = props
    const {set_template_variation} = props
    const {set_is_submitted} = props
    const {is_submitted} = props
    const {has_unreviewed_templates} = props
    const {template_has_been_reviewed} = props

    if (templateIsDraft(template_variation)) {
        return (<div></div>)
    }


    if (is_submitted === true || template_has_been_reviewed === true) {
        return (<h4 className={"branded_h4"}>Feedback Has been Submitted</h4>)
    }


    if (has_unreviewed_templates === true) {

        return (
            <button className={"branded_primary_button"} style={{opacity: 0.5}} onClick={() => {
                alert("All Ads Must Be Approved Or Rejected Before You Can Submit")
            }}>
                Submit Feedback</button>)
    } else {
        return (
            <Nav.Item><SubmitFeedbackModal template_variation={template_variation}
                                           set_template_variation={set_template_variation}
                                           set_is_submitted={set_is_submitted}/></Nav.Item>
        )
    }
}

function ConditionalApproveRejectAll(props) {

    const {template_variation} = props
    const {set_template_variation} = props
    const {is_submitted} = props
    const {template_has_been_reviewed} = props

    if (template_has_been_reviewed === true || is_submitted === true || templateIsDraft(template_variation)) {
        return <div></div>
    }

    return (
        <><Nav.Item><ApprovalAllModalButton template_variations={template_variation}
                                            set_template_variations={set_template_variation}/></Nav.Item>
            <Nav.Item><RejectAllModalButton template_variations={template_variation}
                                            set_template_variations={set_template_variation}/></Nav.Item>
        </>
    )
}

function templateIsDraft(template_variation) {

    const status = template_variation.Results.template_variation_status

    if (status === "Draft") {
        return true
    }

    return false
}

function templateHasBeenReviewed(template_variation) {

    const template_review_status = template_variation.Results.template_variation_status

    if (template_review_status === "Approved" || template_review_status === "Feedback Received") {
        return true
    } else {
        return false
    }
}

export function ApprovalNav(props) {
    const template_variation = props.template_variation
    const related_templates = template_variation.Results.related_templates
    const set_template_variation = props.setTemplateVariation
    const {user, isAuthenticated, isLoading} = useAuth0()
    const user_role = user["http://localhost:3000/roles"][0]
    const [is_submitted, set_is_submitted] = React.useState(false)
    const has_unreviewed_templates = hasUnreviewedTemplate(template_variation)
    const template_has_been_reviewed = templateHasBeenReviewed(template_variation)


    return (
        <Navbar fixed={"top"} className="page_header">
            {user_role != "Client"
                ? <Navbar.Brand><><a href={"/view_all_campaigns"}><img src={brand_logo} width="180"
                                                                       height="35"/></a></>
                </Navbar.Brand>
                : <Navbar.Brand><img src={brand_logo} width="180" height="35"/></Navbar.Brand>}
            <Container fluid>
                <Col>
                    <Row className={"justify-content-end"}>
                        <ConditionalApproveRejectAll template_variation={template_variation}
                                                     set_template_variation={set_template_variation}
                                                     is_submitted={is_submitted}
                                                     template_has_been_reviewed={template_has_been_reviewed}></ConditionalApproveRejectAll>
                    </Row>
                </Col>
                <Col>
                    <Row className={"justify-content-center"}>
                        <Nav.Item><TemplateVariationSelector related_templates={related_templates}
                                                             current_template_variation={template_variation}/></Nav.Item>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Nav.Item>
                            <ConditionalSubmitFeedbackButton template_variation={template_variation}
                                                             set_template_variation={set_template_variation}
                                                             set_is_submitted={set_is_submitted}
                                                             has_unreviewed_templates={has_unreviewed_templates}
                                                             is_submitted={is_submitted}
                                                             template_has_been_reviewed={template_has_been_reviewed}></ConditionalSubmitFeedbackButton>
                        </Nav.Item>

                    </Row>
                </Col>
                <Nav.Item className={"ml-5"}><PublishButton
                    template_variation={template_variation}
                    set_template_variation={set_template_variation}/></Nav.Item>

            </Container>

        </Navbar>
    )

}

function ApprovedInFrontEndState(props) {
    const {setTemplateVariation} = props
    const {ad_template} = props
    const {template_variation} = props

    const mark_as_unreviewed = () => {
        ad_template.approval_status.state = "unreviewed"
        updateAdPreview(ad_template, template_variation, setTemplateVariation)
    }
    return (<Container>
        <Row><h4 className={"branded_h4 approved_text"}>Ad Approved</h4> <CloseButton className={"ml-2"}
                                                                                      onClick={mark_as_unreviewed}></CloseButton></Row>

        <Row><i><p
            className={"branded_text preserve_newlines"}>{`Reason: "${ad_template.approval_status.feedback}"`}</p>
        </i></Row>


    </Container>)
}

function ApprovedInBackendState(props) {
    const ad_template = props.ad_template
    return (<Container><Row><h4 className={" branded_h4 approved_text"}>Ad Approved</h4></Row>
            <Row><i><p className={"branded_text"}>{`Reviewer: ${ad_template.approval_status.approver_email}`}</p>
            </i></Row>
            <Row><i><p
                className={"branded_text preserve_newlines"}>{`Reason: "${ad_template.approval_status.feedback}"`}</p>
            </i></Row>
        </Container>
    )
}

function ApprovalAllModalButton(props) {
    const {template_variations} = props
    const {set_template_variations} = props
    const button_class = "btn btn-success ml-2"
    const [inputValue, setInputValue] = React.useState('')
    const submit_func = () => {
        ApproveAllPressed(template_variations, set_template_variations, inputValue)
    }
    return (
        <BulkUpdateStatusModalButton heading_msg={"Approve All"} body_msg={"If You Have Feedback - Leave It Below"}
                                     button_text={"Approve All"} onClickFunc={submit_func}
                                     setInputValue={setInputValue}
                                     button_class={button_class}
        ></BulkUpdateStatusModalButton>
    )
}

function RejectAllModalButton(props) {
    const {template_variations} = props
    const {set_template_variations} = props
    const button_class = "btn btn-danger ml-2"
    const [inputValue, setInputValue] = React.useState('')
    const submit_func = () => {
        RejectAllPressed(template_variations, set_template_variations, inputValue)
    }
    return (
        <BulkUpdateStatusModalButton heading_msg={"Reject All"} body_msg={"If You Have Feedback - Leave It Below"}
                                     button_text={"Reject All"} onClickFunc={submit_func}
                                     setInputValue={setInputValue}
                                     button_class={button_class}
        ></BulkUpdateStatusModalButton>
    )
}

function BulkUpdateStatusModalButton(props) {
    const {heading_msg} = props
    const {body_msg} = props
    const {button_text} = props
    const {onClickFunc} = props
    const {setInputValue} = props
    const {button_class} = props
    const [show, setShow] = React.useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    return (
        <>
            <button className={button_class} onClick={handleShow}>{button_text}</button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={"branded_h4"}>{heading_msg}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Col>
                            <Row className={"branded_text"}>{body_msg}</Row>
                            <Row style={{marginTop: "10px"}}><textarea rows={"2"} cols={"70"}
                                                                       onChange={event => setInputValue(event.target.value)}
                                                                       className={"branded_text"}/></Row>
                        </Col>
                    </Container>
                </Modal.Body>

                <Modal.Footer>

                    <button className={button_class} onClick={() => {
                        onClickFunc();
                        handleClose()
                    }}>
                        {button_text}
                    </button>
                    <button className="btn btn-secondary" onClick={handleClose}>
                        Cancel
                    </button>

                </Modal.Footer>
            </Modal>
        </>

    )
}

function RejectedInBackendState(props) {
    const ad_template = props.ad_template
    return (<Container>

        <Row><h4 className={"branded_h4 rejected_text"}>Ad Rejected</h4></Row>
        <Row><i><p className={"branded_text"}>{`Reviewer: ${ad_template.approval_status.approver_email}`}</p></i></Row>
        <Row><i><p
            className={"branded_text preserve_newlines"}>{`Reason: "${ad_template.approval_status.feedback}"`}</p>
        </i></Row>
    </Container>)
}


function RejectedInFrontendState(props) {
    const setApprovalStatus = props.setApprovalObject
    const setTemplateVariation = props.setTemplateVariation
    const template_variation = props.template_variation
    const ad_template = props.ad_template
    const ad_feedback = ad_template.approval_status.feedback

    const [inputValue, setInputValue] = React.useState(ad_feedback)

    const mark_as_unreviewed = () => {
        ad_template.approval_status.state = "unreviewed"
        ad_template.approval_status.feedback = ""
        updateAdPreview(ad_template, template_variation, setTemplateVariation)
    }

    React.useEffect(() => {
        ad_template.approval_status.state = "reject_pressed"
        ad_template.approval_status.feedback = inputValue
        updateAdPreview(ad_template, template_variation, setTemplateVariation)
    }, [inputValue])


    return (
        <Container>

            <Row><h4 className={"branded_h4 mb-1 rejected_text"}>Ad Rejected</h4> <CloseButton className={"ml-2"}
                                                                                               onClick={mark_as_unreviewed}></CloseButton></Row>
            <Row>
                <div className={"branded_text"}>If You Have Feedback - Leave It Below</div>
            </Row>
            <Row>
                <textarea rows={"4"} cols={"60"} className={"branded_text preserve_newlines"} value={ad_feedback}
                          onChange={event => setInputValue(event.target.value)}/>
            </Row>
        </Container>
    )

}

function UnReviewedStatusState(props) {
    const setTemplateVariation = props.setTemplateVariation
    const template_variation = props.template_variation
    const ad_template = props.ad_template
    return (<Container>
        <Row>
            <button className="btn btn-success" onClick={() => {
                ad_template.approval_status.state = "marked_for_approval"
                updateAdPreview(ad_template, template_variation, setTemplateVariation)
            }}>Approve
            </button>
            <button className="ml-5 btn btn-danger" onClick={() => {
                ad_template.approval_status.state = "reject_pressed"
                updateAdPreview(ad_template, template_variation, setTemplateVariation)
            }}>Reject
            </button>
        </Row></Container>)
}

function updateAdPreview(ad_preview, template_variation, setTemplateVariation) {
    let ad_preview_id_to_update = ad_preview.id
    if (ad_preview_id_to_update === undefined) {
        console.error("Could Not Update ad_preview - ad_preview.id is Undefined")
    }

    for (var camp = 0; camp < template_variation.Results.ad_template_campaigns.length; camp++) {
        let ad_template_campaign = template_variation.Results.ad_template_campaigns[camp]
        for (var group = 0; group < ad_template_campaign.ad_template_groups.length; group++) {
            let ad_template_groups = ad_template_campaign.ad_template_groups[group]
            for (var template = 0; template < ad_template_groups.ad_templates.length; template++) {
                let ad_template = ad_template_groups.ad_templates[template]
                if (ad_template.id === ad_preview_id_to_update) {
                    template_variation.Results.ad_template_campaigns[camp].ad_template_groups[group].ad_templates[template] = ad_preview
                    setTemplateVariation({...template_variation})
                }
            }

        }

    }


}

function StatusBox(props) {

    const setTemplateVariation = props.setTemplateVariation
    const template_variation = props.template_variation
    const ad_template = props.ad_template
    const approval_state = props.approval_state
    const template_is_draft = templateIsDraft(template_variation)

    //if ad is unreviewed

    if (template_is_draft) {
        return (<div></div>)
    }

    if (approval_state === "unreviewed") {
        return (<UnReviewedStatusState ad_template={ad_template} template_variation={template_variation}
                                       setTemplateVariation={setTemplateVariation}/>)
    }
    if (approval_state === "approved") {
        return (<ApprovedInBackendState ad_template={ad_template}></ApprovedInBackendState>)
    }

    if (approval_state === "rejected") {
        return (<RejectedInBackendState ad_template={ad_template}></RejectedInBackendState>)
    }

    if (approval_state === "reject_pressed") {
        return (<RejectedInFrontendState ad_template={ad_template} template_variation={template_variation}
                                         setTemplateVariation={setTemplateVariation}/>)
    }

    if (approval_state === "marked_for_approval") {
        return (<ApprovedInFrontEndState ad_template={ad_template} template_variation={template_variation}
                                         setTemplateVariation={setTemplateVariation}/>)
    } else {
        return ("Error Could Not Find Status For Ad Template")
    }
}

function adPreviewSwitch(ad_preview_type, ad_template_fields) {
    switch (ad_preview_type) {
        case 'facebook_single_image_ad_mobile':
            return <FacebookSingleImageAdMobile ad_preview_fields={ad_template_fields}/>

        case 'facebook_single_image_ad_desktop':
            return <FacebookSingleImageAdDesktop ad_preview_fields={ad_template_fields}/>

        case 'facebook_carousel_ad_mobile':
            return <FacebookCarouselAdCardsMobile ad_preview_fields={ad_template_fields}/>

        case 'instagram_feed_ad':
            return <InstagramFeedAd ad_preview_fields={ad_template_fields}/>

        case 'instagram_single_image_story_ad':
            return <InstagramSingleImageStoryAd ad_preview_fields={ad_template_fields}/>

        case 'instagram_single_image_feed_ad':
            return <InstagramSingleImageFeedAd ad_preview_fields={ad_template_fields}></InstagramSingleImageFeedAd>

        case 'google_responsive_search_ad_desktop':
            return <GoogleDesktopRSA ad_preview_fields={ad_template_fields}/>

        case 'google_expanded_text_ad_desktop':
            return <GoogleDesktopETA ad_preview_fields={ad_template_fields}/>

        case 'google_responsive_search_ad_mobile':
            return <GoogleMobileRSA ad_preview_fields={ad_template_fields}/>

        case 'google_expanded_text_ad_mobile':
            return <GoogleMobileETA ad_preview_fields={ad_template_fields}/>

        default:
            return <div> {`
    Ad_Preview_Type ${ad_preview_type}
    Is
    Not
    Supported`}</div>

    }

}

function AdPreview(props) {
    const ad_template_fields = props.ad_preview_fields
    const ad_type = props.ad_type

    return (
        adPreviewSwitch(ad_type, ad_template_fields)
    )

}

function AdPreviewRow(props) {
    const template_variation = props.template_variation
    const ad_template = props.ad_template
    const setTemplateVariation = props.setTemplateVariation
    const ad_template_status = props.ad_template.approval_status.state
    const ad_preview_fields = ad_template.ad_template_contents
    const ad_type = ad_template.ad_template_preview_type
    const ad_name = ad_template.ad_template_name
    return (
        <Container fluid>
            <Row>
                <Col><h4 className="branded_h4 mt-1">{ad_name}</h4></Col>
            </Row>
            <Row>
                <Col className={"mt-3 mb-3"}><AdPreview ad_preview_fields={ad_preview_fields}
                                                        ad_type={ad_type}></AdPreview></Col>
                <Col><StatusBox className="col-sm" approval_state={ad_template_status} ad_template={ad_template}
                                setTemplateVariation={setTemplateVariation} template_variation={template_variation}
                /></Col>
            </Row>
        </Container>
    )

}

function AdPreviewGroupAccordion(props) {
    const ad_template_group_name = props.ad_template_group_name
    const setTemplateVariation = props.setTemplateVariation
    const template_variation = props.template_variation
    const ad_preview_rows = ad_template_group_name.ad_templates.map((ad_template, index) => <AdPreviewRow
        ad_template={ad_template} key={index}
        setTemplateVariation={setTemplateVariation}
        template_variation={template_variation}/>)
    return (<Accordion defaultActiveKey="1">
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1" className={"branded_link"}>
                    {ad_template_group_name.ad_template_group_name}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
                <div style={{
                    backgroundImage: `url(${background_image})`,
                    backgroundColor: ""
                }}>
                    <Card.Body>{ad_preview_rows}</Card.Body>
                </div>
            </Accordion.Collapse>
        </Card>
    </Accordion>)
}

function AdPreviewCampaignAccordion(props) {

    const ad_preview_campaign_group = props.ad_preview_group
    const setTemplateVariation = props.setTemplateVariation
    const template_variation = props.template_variation

    const ad_template_group_names = ad_preview_campaign_group.ad_template_groups.map((ad_preview_group, index) =>
        <AdPreviewGroupAccordion key={index}
                                 ad_template_group_name={ad_preview_group}
                                 setTemplateVariation={setTemplateVariation}
                                 template_variation={template_variation}/>)

    return (
        <Accordion defaultActiveKey="1">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className={"branded_link"}>
                        {ad_preview_campaign_group.ad_template_campaign_name}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        {ad_template_group_names}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>


    )


}

function AdPreviewsContainer(props) {
    const template_variation = props.template_variation
    const setTemplateVariation = props.setTemplateVariation

    const ad_preview_groups = template_variation.Results.ad_template_campaigns.map(ad_preview_campaign =>
        <AdPreviewCampaignAccordion
            key={ad_preview_campaign.ad_template_campaign_name}
            ad_preview_group={ad_preview_campaign} setTemplateVariation={setTemplateVariation}
            template_variation={template_variation}/>)
    return (
        <>{ad_preview_groups}</>

    )


}

function TemplateVariationSelector(props) {

    const related_templates = props.related_templates
    const current_template_variation = props.current_template_variation

    const template_links = related_templates.map((template) =>
        <NavDropdown.Item href={` /view_template_variation/${template.template_variation_id}`}
                          key={template.template_variation_id} className={"branded_link"}>
            {template.template_variation_name}
        </NavDropdown.Item>)

    return (
        <>
            <NavDropdown title={`${current_template_variation.Results.template_variation_name}`}
                         id="basic-nav-dropdown" style={{"font-family": "raleway, sans-serif"}}>
                {template_links}
            </NavDropdown>
        </>
    )

}

export function extractTemplateVariationIDFromURL(url_str) {
    //expects url to match this format '/view_campaign/35'
    let split_string = url_str.split('/')
    if (split_string.length >= 4) {
        return undefined
    }

    if (split_string[1] != "view_template_variation") {
        return undefined
    }

    if (split_string[2] == "") {
        return undefined
    }

    let template_variation_id = split_string[2]

    return template_variation_id
}

export default function TemplateVariationIDFetcher() {
    const location = useLocation()
    const template_variation_id = extractTemplateVariationIDFromURL(location.pathname)

    if (template_variation_id) {
        return (TemplateVariationContainer({"template_variation_id": template_variation_id}))
    } else {
        return <Redirect to='/error'/>
    }


}

export function TemplateVariationContainer(props) {
    const template_variation_id = props.template_variation_id
    const [templateVariation, setTemplateVariation] = React.useState()
    const {getAccessTokenSilently} = useAuth0();

    React.useEffect(() => {
        const callSecureApi = async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https:/creative_approvals_tool/api",
                    scope: "read:current_user"
                })

                const response = await fetch(`/api/v1/resources/template_variations/${template_variation_id}`,
                    {
                        headers: {
                            Authorization: `
                             Bearer ${token}`
                        }

                    });
                if (response.ok) {
                    const responseData = await response.json();
                    setTemplateVariation({'Status': 'Complete', 'Results': responseData})
                } else {
                    //throw {'message': response.statusText, 'code': response.status}
                    setTemplateVariation({
                        'Status': 'Error',
                        'Results': {'message': `API Error Occured Fetching Campaigns: ${response.status} : ${response.statusText}`}
                    })
                }
            } catch (err) {
                setTemplateVariation({
                    'Status': 'Error',
                    'Results': {'message': err}
                })

            }
            //return <CampaignContainer data={campaigns}/>
        };
        callSecureApi();

    }, [])


    useBeforeunload((event) => {
        if (templateVariation !== undefined) {
            event.preventDefault();
        }
    });

    if (templateVariation != undefined) {
        if (templateVariation.Status === "Complete") {
            return (
                <>
                    <div style={{
                        backgroundImage: `url(${background_image})`,
                        height: '720px',
                        width: '100%',
                        backgroundColor: ""
                    }}>

                        <ApprovalNav template_variation={templateVariation}
                                     setTemplateVariation={setTemplateVariation}></ApprovalNav>

                        <AdPreviewsContainer
                            template_variation={templateVariation}
                            setTemplateVariation={setTemplateVariation}></AdPreviewsContainer>
                    </div>
                </>
            )
        }
        if (templateVariation.Status === "Error") {
            throw JSON.stringify(templateVariation.Results)
        }
    } else {
        return (

            <LoadingScreen loading_text={"Loading Ad Templates"}></LoadingScreen>


        )
    }

}

