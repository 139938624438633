import React from "react";
import {Accordion, Button, Card, Row, Table} from "react-bootstrap";
import background_image from "../imgs/grey-gb-1280.png";


function MobileCallExtension(props) {
    if (props == undefined) {
        return (<div></div>)
    }

    const call_extension = props.call

    if (call_extension === undefined) {
        return (<div></div>)
    }


    return (
        <>
            <hr className="line _ngcontent-awn-CM-32"/>
            <div id={"call-preview"}
                 className="action-item _ngcontent-awn-CM-32 _nghost-awn-CM-57 mobile">
                <div className="call _ngcontent-awn-CM-57">
                    <div id={"material-icon"}
                         className="phone _ngcontent-awn-CM-57 _nghost-awn-CM-18"
                         icon="phone"><i
                        className="material-icon-i material-icons-extended _ngcontent-awn-CM-18"
                        role="img" aria-hidden="true">phone</i></div>
                    Call ‪{call_extension[0]['Phone Number']}‬
                </div>
            </div>
        </>
    )
}


function MobileCallouts(props) {

    if (props == undefined) {
        return (<div></div>)
    }

    const callouts = props.callout

    if (callouts === undefined) {
        return (<div></div>)
    }

    const callout_components = callouts.map(callout => {

            callout['Callout Text'] = callout['Callout Text'].replace(".", "")
            return <span className="callout _ngcontent-awn-CM-31">{" " + callout["Callout Text"] + "."}</span>
        }
    )

    return (<div id={"tank-preview"} className="_ngcontent-awn-CM-29 _nghost-awn-CM-31 with-highlighting">
        {callout_components}
    </div>)

}


function MobileSitelinks(props) {

    if (props == undefined) {
        return (<div></div>)
    }

    const sitelinks = props.sitelink

    if (sitelinks === undefined) {
        return (<div></div>)
    }


    const sitelink_components = sitelinks.map(sitelink => {

        return <li className="sitelink-extension-text _ngcontent-awn-CM-56"><a
            className="sitelink _ngcontent-awn-CM-56">{sitelink["Sitelink Text"]}</a></li>

    })

    return (
        <div id={"sitelink-preview"} className="_ngcontent-awn-CM-32 _nghost-awn-CM-56">
            <ul className="_ngcontent-awn-CM-56 mobile" inlinemobile="false">
                {sitelink_components}
            </ul>
        </div>

    )
}


function TextAdMobile(props) {
    // googles html layout is logic. Invididual extensions are added or removed onto main ad. custom extensions (replaced with div ids for react compatibility) can be used to select extensions


    const ad_preview_fields = props.ad_preview_fields

    const headline_1 = ad_preview_fields['Headline 1']
    const headline_2 = ad_preview_fields['Headline 2']
    const headline_3 = ad_preview_fields['Headline 3']
    const description_1 = ad_preview_fields['Description 1']
    const description_2 = ad_preview_fields['Description 2']
    const final_url = ad_preview_fields['Final URL']


    //display url is the domain plus the two paths

    const path_1 = ad_preview_fields['Path 1']
    const path_2 = ad_preview_fields['Path 2']

    const display_url = createDisplayURL(final_url, path_1, path_2)
    const extensions = ad_preview_fields['Extensions']

    const mobile_sitelinks = MobileSitelinks(extensions)
    const mobile_callouts = MobileCallouts(extensions)
    const mobile_call = MobileCallExtension(extensions)

    //extensions are rendered differently if mobile or desktop

    //new extension types will require modifcations to existing react templates


    return (
        <>
            <link href={"/ad_template_styles/google_text_ad_mobile/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>
            <div id="vta-preview" className="_ngcontent-awn-CM-26 _nghost-awn-CM-29 with-mobile-frame mobile">
                <div id="preview-unit" className="_ngcontent-awn-CM-29 _nghost-awn-CM-30 h-ad mobile with-highlighting">
                    <div id={"preview-chrome"} className="_nghost-awn-CM-33 mobile-frame">
                        <div className="camera _ngcontent-awn-CM-33"></div>
                        <div className="banner _ngcontent-awn-CM-33"></div>
                        <div className="mobile-image _ngcontent-awn-CM-33">
                            <div className="preview-content _ngcontent-awn-CM-30">
                                <div className="url-section _ngcontent-awn-CM-30"><span aria-hidden="true"
                                                                                        className="badge _ngcontent-awn-CM-30">Ad <span
                                    className="interpunct _ngcontent-awn-CM-30">·</span></span> <span
                                    className="visurl _ngcontent-awn-CM-30"><div id={"preview-visurl"}
                                                                                 className="_ngcontent-awn-CM-29">‪{display_url}‬</div></span>
                                    <div id={"material-icon"} className="info _ngcontent-awn-CM-30 _nghost-awn-CM-18"
                                         icon="info"
                                         size="small"><i
                                        className="material-icon-i material-icons-extended _ngcontent-awn-CM-18"
                                        role="img" aria-hidden="true">info</i></div>
                                </div>
                                <div className="headline-section _ngcontent-awn-CM-30">
                                    <div className="headline-group _ngcontent-awn-CM-30">
                                        <div id={"preview-headline"} className="_ngcontent-awn-CM-29"><span
                                            className="headline _ngcontent-awn-CM-29">{headline_1} | {headline_2} | {headline_3}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="description-section _ngcontent-awn-CM-30">
                                    <div className="description-group _ngcontent-awn-CM-30">
                                        <div id={"preview-description"}
                                             className="description _ngcontent-awn-CM-29"><span
                                            className="description-line _ngcontent-awn-CM-29">{description_1}</span>
                                            <span
                                                className="description-line _ngcontent-awn-CM-29">{description_2}</span>
                                            {mobile_callouts}
                                        </div>
                                    </div>
                                </div>
                                <div id={"preview-details"} className="_ngcontent-awn-CM-29">
                                    <div id={"extensions-under-ad-preview"}
                                         className="_ngcontent-awn-CM-29 _nghost-awn-CM-32 mobile with-highlighting">
                                        {mobile_sitelinks}
                                        {mobile_call}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function createDisplayURL(final_url, path_1, path_2) {

    let root_domain = final_url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)[1];

    if (root_domain===null){
        throw "final_url is not a valid url"
    }

    root_domain=root_domain.replace("www.","")

    let display_url = root_domain + '/' + path_1 + '/' + path_2

    display_url = display_url.toLowerCase()
    // replaces empty space throughout string using regex and the g (global) regex flag
    display_url = display_url.replace(/ /g, "_")
    return display_url

}


function DesktopSitelinks(props) {

    if (props == undefined) {
        return (<div></div>)
    }

    const sitelinks = props.sitelink

    if (sitelinks === undefined) {
        return (<div></div>)
    }

    const components = sitelinks.map(sitelink => {
        return (<li className="sitelink-extension-text _ngcontent-awn-CM-56"><a
            className="sitelink _ngcontent-awn-CM-56"><h3
            className="_ngcontent-awn-CM-56">{sitelink["Sitelink Text"]}</h3>
        </a>
            <div
                className="sitelink-description _ngcontent-awn-CM-56">{sitelink["Sitelink Description 1"]}<br
                className="_ngcontent-awn-CM-56"/>{sitelink["Sitelink Description 2"]}
            </div>
        </li>)
    })


    return (
        <div id={'sitelink-preview'} className="_ngcontent-awn-CM-32 _nghost-awn-CM-56">
            <ul className="_ngcontent-awn-CM-56" inlinemobile="false">
                {components}
            </ul>
        </div>
    )

}


function DesktopCallouts(props) {
    if (props == undefined) {
        return (<div></div>)
    }

    const callouts = props.callout

    if (callouts === undefined) {
        return (<div></div>)
    }

    const components = callouts.map(callout => {
            callout['Callout Text'] = callout['Callout Text'].replace(".", "")
            return (
                <span className="callout _ngcontent-awn-CM-31">{" " + callout['Callout Text'] + "."}</span>
            )

        }
    )

    return (
        <div id={'tank-preview'} className="_ngcontent-awn-CM-29 _nghost-awn-CM-31 with-highlighting">
            {components}
        </div>
    )

}


function DesktopCall(props) {

    if (props == undefined) {
        return (<div></div>)
    }

    const call = props.call

    if (call === undefined) {
        return (<div></div>)
    }

    return (<div id={"call-preview"}
                 className="desktop-call-preview _ngcontent-awn-CM-29 _nghost-awn-CM-57">
        <div className="call _ngcontent-awn-CM-57">{call[0]["Phone Number"]}</div>
    </div>)

}

function TextAdDesktop(props) {

    const ad_preview_fields = props.ad_preview_fields

    const headline_1 = ad_preview_fields['Headline 1']
    const headline_2 = ad_preview_fields['Headline 2']
    const headline_3 = ad_preview_fields['Headline 3']
    const description_1 = ad_preview_fields['Description 1']
    const description_2 = ad_preview_fields['Description 2']
    const final_url = ad_preview_fields['Final URL']


    //display url is the domain plus the two paths

    const path_1 = ad_preview_fields['Path 1']
    const path_2 = ad_preview_fields['Path 2']

    const display_url = createDisplayURL(final_url, path_1, path_2)

    const extensions = ad_preview_fields['Extensions']

    const sitelinks = DesktopSitelinks(extensions)
    const callouts = DesktopCallouts(extensions)
    const call = DesktopCall(extensions)

    return (
        <div>
            <link href={"/ad_template_styles/google_text_ad_desktop/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>

            <div id={"vta-preview"} className="_ngcontent-awn-CM-26 _nghost-awn-CM-29">
                <div id={'preview-unit'}
                     className="_ngcontent-awn-CM-29 _nghost-awn-CM-30 h-ad with-highlighting">{/**/}
                    <div id={'preview-chrome'} className="_ngcontent-awn-CM-30 _nghost-awn-CM-33">{/**/}
                        <div className="banner _ngcontent-awn-CM-33"/>
                        <div className="mobile-image _ngcontent-awn-CM-33">
                            <div className="preview-content _ngcontent-awn-CM-30">
                                <div className="url-section _ngcontent-awn-CM-30"><span aria-hidden="true"
                                                                                        className="badge _ngcontent-awn-CM-30">Ad <span
                                    className="interpunct _ngcontent-awn-CM-30">·</span></span> <span
                                    className="visurl _ngcontent-awn-CM-30"><div id={"preview-visurl"}
                                                                                 className="_ngcontent-awn-CM-29">{display_url}</div></span>{/**/}{/**/}<span
                                    className="info _ngcontent-awn-CM-30"/> {/**/}
                                    {call}
                                </div>
                                <div className="headline-section _ngcontent-awn-CM-30">{/**/}
                                    <div className="headline-group _ngcontent-awn-CM-30">
                                        <div id={"preview-headline"} className="_ngcontent-awn-CM-29"><span
                                            className="headline _ngcontent-awn-CM-29">{headline_1} {/**/}| {headline_2} {/**/}| {headline_3}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="description-section _ngcontent-awn-CM-30">
                                    <div className="description-group _ngcontent-awn-CM-30">
                                        <div id={'preview-description'}
                                             className="description _ngcontent-awn-CM-29"><span
                                            className="description-line _ngcontent-awn-CM-29">{description_1}</span> {/**/}<span
                                            className="description-line _ngcontent-awn-CM-29">{description_2}</span>
                                            {callouts}
                                        </div>
                                    </div>
                                    {/**/}</div>
                                <div id={'preview-details'} className="_ngcontent-awn-CM-29">
                                    <div id={'extensions-under-ad-preview'}
                                         className="_ngcontent-awn-CM-29 _nghost-awn-CM-32 with-highlighting">{/**/}{/**/}{/**/}{/**/}{/**/}
                                        {sitelinks}
                                    </div>
                                </div>
                                {/**/}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)


}


function RsaTable(props) {

    const ad_preview_fields = props.ad_preview_fields
    const headline_array = []
    const description_array = []
    const table_rows = []

    for (const property in ad_preview_fields) {
        if (property.includes('Headline') && ad_preview_fields[property] != "") {
            headline_array.push(ad_preview_fields[property])
        }

        if (property.includes('Description') && ad_preview_fields[property] != "") {
            description_array.push(ad_preview_fields[property])
        }
    }

    //description array could theoretically be longer than headline array
    let longest_array
    if (description_array.length > headline_array.length) {
        longest_array = description_array
    } else {
        longest_array = headline_array
    }

    for (const row_num in longest_array) {
        let headline_value = headline_array[row_num]
        let description_value = description_array[row_num]
        table_rows.push(<tr>
            <td>{headline_value}</td>
            <td>{description_value}</td>
        </tr>)
    }

    return (<Accordion defaultActiveKey="1" className={"mt-2"}>
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" className={"branded_link"}>
                    See All Headlines & Descriptions
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body><Table>
                    <tr>
                        <th>Headlines</th>
                        <th>Descriptions</th>
                    </tr>
                    {table_rows}
                </Table></Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>)

}


export function GoogleMobileETA(props) {
    const ad_preview_fields = props.ad_preview_fields

    //render remaining headlines and description in table

    return (<TextAdMobile ad_preview_fields={ad_preview_fields}></TextAdMobile>)
}

export function GoogleDesktopETA(props) {
    const ad_preview_fields = props.ad_preview_fields

    return (<TextAdDesktop ad_preview_fields={ad_preview_fields}/>)
}

export function GoogleMobileRSA(props) {
    const ad_preview_fields = props.ad_preview_fields


    //render remaining headlines and description in table

    return (<Row><TextAdMobile ad_preview_fields={ad_preview_fields}></TextAdMobile>
        <RsaTable ad_preview_fields={ad_preview_fields}></RsaTable>
    </Row>)
}


export function GoogleDesktopRSA(props) {
    const ad_preview_fields = props.ad_preview_fields

    return (<Row>

        <TextAdDesktop ad_preview_fields={ad_preview_fields}/>
        <RsaTable ad_preview_fields={ad_preview_fields}></RsaTable>
    </Row>)
}