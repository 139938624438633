import React from 'react';
import * as view_all_campaigns from "../view_campaigns/view_all_campaigns";
import {Route, Switch, Error} from 'react-router-dom';
import *  as errors from '../errors/error';
import AppNavbar from "./navbar"
import {ProtectedRoute, PreSharedPasswordRoute} from "../auth/private_route"
import CampaignContainer from "../create_campaign/create_campaign"
import * as campaign_contents from "../campaign_contents/campaign_contents"
import CreateTemplateVariationContainer, {ImageUploadTest} from "../create_template_variation/create_template_variation"
import TemplateVariationIDFetcher, {
    PublishButton,
} from "../template_variation_view/template_variation_view";
import ClientInviteCampaignIDFetcher from "../client_invite/client_invite"
import UserInviteCampaignIDFetcher from "../user_invite/user_invite";
import LoginPage from "../home/home";
import {useAuth0} from "@auth0/auth0-react";
import {LoadingScreenTest} from "../loading_screen/loading_screen";
import {ResourceIsForbidden} from "../errors/error";
import LogRocket from 'logrocket';


export function App() {

    const {user, isAuthenticated, isLoading} = useAuth0();
    if (user !== undefined) {
        const email = user['email']

        if (typeof email !== "undefined") {
            LogRocket.identify(user.email)
        }
    }


    return (
        <main>
            {isAuthenticated ? <AppNavbar/> : <div></div>}
            <Switch>
                <Route exact path='/' component={LoginPage}/>
                <Route exact path='/authenticate' component={() => {
                    return (<div></div>)
                }}/>
                <ProtectedRoute exact path='/view_all_campaigns'
                                component={view_all_campaigns.FetchCampaignsAsync}/>
                <ProtectedRoute exact path='/create_new_campaign' component={CampaignContainer}/>
                <ProtectedRoute exact path={'/view_campaign/:campaign_id'}
                                component={campaign_contents.CampaignIDFetcher}/>
                <ProtectedRoute exact path={'/campaigns/:campaign_id/invite_client'}
                                component={ClientInviteCampaignIDFetcher}/>
                <ProtectedRoute exact path={'/campaigns/:campaign_id/invite_user'}
                                component={UserInviteCampaignIDFetcher}/>
                <ProtectedRoute exact path='/campaigns/:campaign_id/create_new_template_variation'
                                component={CreateTemplateVariationContainer}/>
                <ProtectedRoute exact path={'/view_template_variation/:template_variation_id'}
                                component={TemplateVariationIDFetcher}/>
                <ProtectedRoute exact path={'/publish_test'}
                                component={PublishButton}/>
                component={PublishButton}/>
                <Route exact path={'/loading_screen'} component={LoadingScreenTest}/>
                <Route exact path={'/resource_forbidden'} component={ResourceIsForbidden}/>
                <Route component={errors.PageNotFound}/>


            </Switch>
        </main>

    )

}