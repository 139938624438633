import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import {Col, Container, Form, Row, Spinner, Toast} from "react-bootstrap";
import {jsonToCSV, readString} from 'react-papaparse'
import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";


export function extractCampaignIDFromLocation(url_str) {
    //expects url to match this format '/view_campaign/35'
    let split_string = url_str.split('/')
    if (split_string.length >= 5) {
        return undefined
    }

    if (split_string[1] != "campaigns") {
        return undefined
    }

    if (split_string[2] == "") {
        return undefined
    }

    let campaign_id = split_string[2]

    return campaign_id
}


export default function CreateTemplateVariationContainer() {
    const [statusMessages, setStatusMessages] = useState([])


    if (statusMessages.length > 0) {

        if (statusMessages[0].message_type === 'Internal Error') {
            throw statusMessages[0].message
        }
    }

    const location = useLocation()
    const campaign_id = extractCampaignIDFromLocation(location.pathname)

    return (<Container>
            <Row><Col>
                <UploadForm props={{
                    'statusMessages': statusMessages,
                    'setStatusMessages': setStatusMessages,
                    'campaign_id': campaign_id
                }}/>
                <StatusBox props={{'statusMessages': statusMessages, 'setStatusMessages': setStatusMessages}}/>
            </Col>
                <Col><ExampleTemplateBox></ExampleTemplateBox></Col>
            </Row>
        </Container>
    )

}


function ExampleTemplateBox() {

    return (
        <Col>
            <h4 className={"branded_h4"}>Example Bulksheets</h4>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Master+Bulksheet+For+All+Ad+Types.zip"}>Master
                Bulksheet</a></li>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Facebook+Single+Image+Ad+-+Mobile.zip"}>Facebook
                Single Image Ad - Mobile</a>
            </li>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Facebook+Single+Image+Ad+-+Desktop.zip"}>Facebook
                Single Image Ad - Desktop</a></li>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Facebook+Carousel+Ad+-+Mobile.zip"}>Facebook
                Carousel Ad - Mobile </a></li>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Instagram+Multi+Image+Feed+Ad+-+Mobile.zip"}>Instagram
                Multi Image Feed Ad</a></li>
            <li><a className={"branded_link"}
                   href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Instagram+Single+Image+Feed+Ad+-+Mobile.zip"}>Instagram
                Single Image Feed Ad</a></li>
            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/Instagram+Single+Image+Story+Ad+-+Mobile.zip"}>Instagram
                Single Image Story Ad</a></li>

            <li><a
                className={"branded_link"}
                href={"https://semetrical-brand-assets.s3.eu-west-1.amazonaws.com/google_text_ads_with_all_extensions.csv"}>Google
                Responsive Search Ads & Expanded Text Ads</a></li>
        </Col>)

}


function ReadFilesAndStoreInHook(array_of_file_objects, setCsvJson, statusMessageSetter) {

    function append_file_data_to_hook(data, setCsvJson) {

        setCsvJson(prevArray => [...prevArray, data])
    }

    function loadHandler(event) {
        let parsed_json = ParseToCsv(event.target.result)
        let file_name = event.target.fileName
        console.log(parsed_json)

        ///expand

        if (parsed_json.errors > 0) {
            let error
            for (error in parsed_json.errors) {
                statusMessageSetter(prevArray => [...prevArray, {
                    'message': error.message,
                    'message_type': 'ERROR'
                }])
            }
        } else {
            //add file_name as key to each row of parsed_result
            parsed_json.data = parsed_json.data.map(data_obj => ({...data_obj, Ad_template_file_name: file_name}))
            append_file_data_to_hook(parsed_json, setCsvJson)
            statusMessageSetter(prevArray => [...prevArray, {
                'message': `"${file_name}" Containing ${parsed_json.data.length} Rows Has Uploaded`,
                'message_type': 'SUCCESS'
            }])
        }
    }

    for (var i = 0; i < array_of_file_objects.length; i++) {
        var file_object = array_of_file_objects[i]
        let reader = new FileReader()
        reader.fileName = file_object.name
        reader.onload = loadHandler
        reader.readAsText(file_object, "utf-8")
    }

}

function ParseToCsv(csv_string) {
    try {
        let parsed_data = readString(csv_string, {delimiter: ",", header: true, skipEmptyLines: true})
        return parsed_data
    } catch (err) {
        console.log(err)
    }


}

export function FileHasValidMimeType(file_object, mimetypes) {

    for (let mimetype_num = 0; mimetype_num < mimetypes.length; mimetype_num++)
        if (mimetypes[mimetype_num] === file_object.type) {
            return true
        }

    return false
}


function StoreImageFileReference(file_element_id, status_message_setter, setImageStoreRef) {

    //add pointer to file object to array

    var valid_files = []
    const valid_mimetypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png']
    var selectedFiles = document.getElementById(file_element_id).files;
    if (selectedFiles instanceof File) {
        selectedFiles = [selectedFiles]
    }

    for (var i = 0; i < selectedFiles.length; i++) {
        let file_object = selectedFiles[i]

        if (FileHasValidMimeType(file_object, valid_mimetypes)) {
            valid_files.push(file_object)
        } else {
            status_message_setter(prevArray => [...prevArray, {
                'message': `Uploaded File ${file_object.name} Is Not One These File Formats [.jpg .gif .bmp .png]`,
                'message_type': 'ERROR'
            }])
        }
    }

    setImageStoreRef(valid_files)

    //generate upload uri for S3

    //add file blobs to post request + send


}


function UploadFiles(file_element_id, status_message_setter, setCsvJson) {
    //clear any previously loaded files
    setCsvJson([])
    var selectedFiles = document.getElementById(file_element_id).files;
    if (selectedFiles instanceof File) {
        selectedFiles = [selectedFiles]
    }

    var valid_files = []
    for (var i = 0; i < selectedFiles.length; i++) {
        let file_object = selectedFiles[i]
        if (file_object.type === "text/csv" || file_object.name.includes('.csv')) {
            valid_files.push(file_object)
        } else {
            status_message_setter(prevArray => [...prevArray, {
                'message': `Uploaded File ${file_object.name} is not a csv file`,
                'message_type': 'ERROR'
            }])
            console.warn(`Browser Thinks Uploaded File ${file_object.name} is not a csv file - File MimeType Is ${file_object.type}`)
        }
    }

    ReadFilesAndStoreInHook(valid_files, setCsvJson, status_message_setter)
}


function CsvStringToUrl(csv_string, file_name) {

    let file = new File([csv_string], file_name, {type: 'text/csv'})
    const objectURL = URL.createObjectURL(file)
    return objectURL

}

function SaveFileFromURL(object_url, filename) {
    const link = document.createElement('a');
    link.href = object_url;
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
}


function ValidationResultToGroups(validated_json) {
    var group_of_files = {}

    for (const row_count in validated_json) {
        let row = validated_json[row_count]
        let file_name = row.Ad_template_file_name
        delete row.Ad_template_file_name

        if (group_of_files[file_name] === undefined) {
            group_of_files[file_name] = []
            group_of_files[file_name].push(row)
        } else {
            group_of_files[file_name].push(row)
        }
    }

    return group_of_files
}

function EscapeErrorMessages(row_array) {
    let error_key = "errors"
    //escapes error messages at top level and extension level
    for (const row_num in row_array) {
        let row_obj = row_array[row_num]

        if (error_key in row_obj) {
            row_obj[error_key] = JSON.stringify(row_obj[error_key])
        }

        if ('Extensions' in row_obj) {
            for (const extension_count in row_obj.Extensions) {
                let extension = row_obj.Extensions[extension_count]
                if ((error_key) in extension) {
                    extension[error_key] = JSON.stringify(extension[error_key])

                }

            }

        }

    }

    return row_array
}

export function GenerateColumns(input_json) {
    /**
     * Generates an array of unique key names from an array of objects for passing to papaparse
     * @param {array_of_objects} input_json - Array of objects 1 level deep
     * @return {array} array of the unique key names (e.g. column names) found in input_json
     * */
    let column_names = new Set()
    //loop through keys per object --> o(n)
    //add each key to set --> o(1)*n
    //push each key in set to Array --> o(n)
    //time complexity = 3n
    input_json.forEach(ad_template => {
        Object.keys(ad_template).forEach(key => {
            column_names.add(key)
        })
    })

    return Array.from(column_names)
}

function DownloadFiles(validated_json) {

    //group by ad_template_file_name
    //escape error col
    let group_of_files = ValidationResultToGroups(validated_json)
    //turn each file in csv string
    //start file download for each string
    for (const property in group_of_files) {
        let row_array = group_of_files[property]
        row_array = EscapeErrorMessages(row_array)
        const columns_in_file=GenerateColumns(row_array)
        row_array = UnnestGoogleTextAdsWithExtensions(row_array)
        row_array = jsonToCSV(row_array,{columns:columns_in_file})
        let url = CsvStringToUrl(row_array, property)
        SaveFileFromURL(url, property)
    }

}

function Message(props) {
    const [showA, setShowA] = useState(true);
    const toggleShowA = () => setShowA(!showA)
    return (
        <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
                {props.props.message_type}
            </Toast.Header>
            <Toast.Body>
                {props.props.message}
            </Toast.Body>

        </Toast>

    )
}


function StatusBox(props) {
    const status_messages = props.props.statusMessages

    const model = status_messages
    if (model) {
        const messages = model.map((message_obj, index) => <Message key={index} props={message_obj}/>)
        return (
            <Container className={"mt-5 branded_text"}>
                {messages}
            </Container>
        )
    } else {
        return <></>
    }

}


async function CreateTemplate(prepared_json, access_token) {

    const response = await fetch('/api/v1/resources/template_variations', {
        method: 'POST',
        body: JSON.stringify(prepared_json),
        headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${access_token}`}
    })

    if (response.status === 422 || response.ok) {
        return {'status': 'success', 'response': response}
    } else {
        return {'status': 'error', 'response': response}

    }
}


function isExtension(row_obj) {
    if (!("Extension_Type" in row_obj)) {
        return false
    }
    if (row_obj["Extension_Type"] != "") {
        return true
    }
    return false
}


export function UnnestGoogleTextAdsWithExtensions(array_of_ad_templates) {

    for (const row_num in array_of_ad_templates) {
        const row_object = array_of_ad_templates[row_num]


        if ("Extensions" in row_object) {

            //ads error key to ad object so that papaParse jsonToCSV works correctly
            //jsonToCSV uses the first row of data in array to establish headers!
            if (!('errors' in row_object)) {
                row_object.errors = ""
            }

            array_of_ad_templates.push(...row_object["Extensions"])
            delete row_object.Extensions
        }
    }


    return array_of_ad_templates
}

export function NestExtensionsWithGoogleTextAds(array_of_object_rows) {

    //loop through list
    //missing keys should lead to console warning and continuation to next object
    let extensions_group = {}
    let output_rows = []
    for (const row_num in array_of_object_rows) {
        let row_object = array_of_object_rows[row_num]
        let is_extension = isExtension(row_object)

        if (!("Ad_template_name" in row_object)) {
            console.warn({
                "message": "Could Not Find 'Ad_Template_Name' Key In Row Object - We Will Not Attempt To Merge This With Ads",
                "row_object": row_object
            })
            output_rows.push(row_object)
        }

        let ad_template_name = row_object["Ad_template_name"]
        // if the row_object is an extension push to extension_group under the ad_template_name
        if (is_extension) {
            if (ad_template_name in extensions_group) {
                extensions_group[ad_template_name].push(row_object)
            } else {
                extensions_group[ad_template_name] = [row_object]
            }
            // if the row_object is an ad then push it to the output_list
        } else {
            output_rows.push(row_object)
        }
    }

    // insert the extensions for the ad_template_name each time you see an ad_template_name and the row is not an extension
    for (const row_num in output_rows) {

        let row_object = output_rows[row_num]

        if ("Ad_template_name" in row_object) {
            let ad_template_name = row_object["Ad_template_name"]
            if ((ad_template_name in extensions_group) && (isExtension(row_object) === false)) {
                row_object["Extensions"] = extensions_group[ad_template_name]
            }
        }
    }

    return output_rows
}


function PrepareJson(csvJson, template_variation_name, campaign_id, s3_folder_id) {
    //expects instances of papaparse result objects in key:value format
    let prepared_json = {
        'campaign_id': campaign_id,
        'template_variation_name': template_variation_name,
        'ad_templates': []
    }

    for (var i = 0; i < csvJson.length; i++) {
        let papa_parse_result = csvJson[i]
        if (papa_parse_result.data.length > 0) {
            if (typeof papa_parse_result.data[0] === 'object') {

                const ad_template_data = papa_parse_result.data
                ad_template_data.map(row => row['S3_folder_id'] = s3_folder_id)
                prepared_json.ad_templates.push(...ad_template_data)
            } else {
                let err_msg = `papa_parse_result was not an object ${papa_parse_result}`
                console.error(err_msg)
                throw err_msg
            }

        } else {
            console.warn('Could Not Find Any Rows In papa_parse_result')
        }

    }

    prepared_json['ad_templates'] = NestExtensionsWithGoogleTextAds(prepared_json['ad_templates'])

    return prepared_json
}

function GenerateRandomFolderID() {

    return parseInt(Math.random() * 10000000)
}

async function GenerateSignedEndpointForUploadToS3(token, folder_name, file_name, mime_type) {

    const response = await fetch(`https://nuuy9mmwd5.execute-api.eu-west-2.amazonaws.com/default/getPresignedURLForS3ImageUpload?folder_id=${folder_name}&file_name=${file_name}&mimetype=${mime_type}`, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })

    if (response.ok) {
        const response_body = await response.json()
        return response_body['uploadURL']
    } else {
        throw({
            "message": `Error Occurred Generating Signed URL`,
            "status_code": response.status,
            "server_message": response.statusText
        })
    }

}

async function UploadImageBlobToS3(endpoint_url, blob_data) {

    const response = await fetch(endpoint_url, {method: 'PUT', body: blob_data})

    if (response.ok) {
        return true
    } else {
        throw(`Error Occurred Uploading Images ${response.status}: ${response.statusText}`)
    }

}


async function UploadImageToS3(file_object, token, s3_folder_id) {

    const upload_url = await GenerateSignedEndpointForUploadToS3(token, s3_folder_id, file_object.name, file_object.type)
    const uploaded = await UploadImageBlobToS3(upload_url, file_object)
    return uploaded

}

export function ImageUploadTest() {

    const [imageStoreRefs, setImageStoreRefs] = React.useState()
    const [statusMessages, setStatusMessages] = React.useState()
    const {getAccessTokenSilently} = useAuth0()


    return (
        <form onSubmit={async () => {
            const token = await getAccessTokenSilently({
                audience: "https:/creative_approvals_tool/api",
                scope: "read:current_user"
            })
            SendImageFilesToS3Folder(imageStoreRefs, token)
        }}>
            <input type="file" id={"image_input"} data-testid={"image_upload"} multiple onChange={() => {
                StoreImageFileReference('image_input', setStatusMessages, setImageStoreRefs)
            }}/>

            <button type="submit">Submit</button>

        </form>
    )
}

async function SendImageFilesToS3Folder(ImageStoreRefs, token, s3_folder_id) {

    for (const image_num in ImageStoreRefs) {
        const file_object = ImageStoreRefs[image_num]
        await UploadImageToS3(file_object, token, s3_folder_id)
    }

    return true
}

async function SendTemplateVariationJson(csvJson, setStatusMessages, setSubmissionState, getAccessTokenSilently, campaign_id, template_variation_name, setValidatedJson, setErrorsFound, history, s3_folder_id) {
    console.log(`Sending ${csvJson} To API`)

    const token = await getAccessTokenSilently({
        audience: "https:/creative_approvals_tool/api",
        scope: "read:current_user"
    })

    const prepared_json = PrepareJson(csvJson, template_variation_name, campaign_id, s3_folder_id)
    const results = await CreateTemplate(prepared_json, token)

    if (results.status === "error") {
        setStatusMessages(prevArray => [...prevArray, {
            'message': `Network Error Occured When Trying To Create Template Variation : ${results.response.status} ${results.response.statusText}`,
            'message_type': 'ERROR'
        }])

        setSubmissionState("submitted")

    } else if (results.status === "success") {
        let response_json = await results.response.json()
        let validation_obj = response_json.validation_statistics
        if (validation_obj != undefined) {
            let err_msg = `${validation_obj.error_total} Errors Found For Template Variation ${response_json.template_variation_name}`
            console.warn(err_msg)

            setStatusMessages(prevArray => [...prevArray, {
                'message': err_msg,
                'message_type': 'ERROR'
            }])
            setValidatedJson(response_json.ad_templates)
            setErrorsFound(true)
            setSubmissionState("submitted")


        } else {

            let success_message = `Template Variation Named ${response_json.template_variation_name} Created Successfully`
            console.log(success_message)
            setSubmissionState("submitted")
            setErrorsFound(false)
            setStatusMessages(prevArray => [...prevArray, {
                'message': success_message,
                'message_type': 'SUCCESS'
            }])

        }

    }
}


function UploadForm(props) {
    const [errorsFromAPIFound, setErrorsFound] = useState(false)
    const [csvJson, setCsvJson] = useState([])
    const [validatedJson, setValidatedJson] = useState([])
    const setStatusMessages = props.props.setStatusMessages
    const history = useHistory()
    const campaign_id = props.props.campaign_id
    const [submission_state, setSubmissionState] = useState()
    const [ImageStoreRef, setImageStoreRefs] = useState([])

    const {getAccessTokenSilently} = useAuth0()

    useEffect(() => {
        if (submission_state === "submitted" && errorsFromAPIFound === false) {
            history.push(`/view_campaign/${campaign_id}`)
        }
    }, [submission_state, errorsFromAPIFound])

    function validate(values) {
        const errors = {};
        if (!values.template_variation_name) {
            errors.template_variation_name = "Required"
        }
        return errors
    }

    //look at contents of csvJSON useEffect hook to create status message if contents have changed
    //trigger file uploaded message here

    const formik = useFormik({
                initialValues: {
                    template_variation_name: ""
                }, validate,
                onSubmit: async values => {
                    //check that we have papaparse file obect in csvJSON
                    //raise status message if we do not

                    if (csvJson.length == 0 | csvJson === undefined) {
                        setStatusMessages(prevArray => [...prevArray, {
                            'message': `Please Upload One Or More Ad Template Bulksheets Before Submitting`,
                            'message_type': 'ERROR'
                        }])
                        return
                    }

                    setSubmissionState("submitting")
                    if (submission_state === "submitting") {
                        console.log("bloorp")
                    }
                    try {

                        const token = await getAccessTokenSilently({
                            audience: "https:/creative_approvals_tool/api",
                            scope: "read:current_user"
                        })

                        const s3_folder_id = GenerateRandomFolderID()
                        const uploaded = await SendImageFilesToS3Folder(ImageStoreRef, token, s3_folder_id, setStatusMessages)

                        if (uploaded) {
                            const template_variation_name = formik.values.template_variation_name
                            console.log(submission_state)
                            await SendTemplateVariationJson(csvJson, setStatusMessages, setSubmissionState, getAccessTokenSilently, campaign_id, template_variation_name, setValidatedJson, setErrorsFound, history, s3_folder_id)

                            console.log(submission_state, errorsFromAPIFound)
                        }
                        // if the template_variation endpoint has created successfully
                    } catch (error) {
                        setSubmissionState("ERROR")
                        setStatusMessages([{
                            'message': JSON.stringify(error),
                            'message_type': 'Internal Error'
                        }])
                    }


                },
            }
        )
    ;

    return (
        <>
            <h4 className={"mb-2 branded_h4"}>Create Template Variation</h4>
            <form onSubmit={formik.handleSubmit}>
                <Form.Group controlId={"template_variation_name"}>
                    <label htmlFor="template_variation_name" className={"mr-1 branded_text"}>Template Variation
                        Name</label>
                    <input id={'template_variation_name'} name={'template_variation_name'} type={"text"}
                           className={"branded_text"}
                           onChange={formik.handleChange} value={formik.values.template_variation_name}/>
                    <div
                        style={{color: "red"}}>{formik.errors.template_variation_name && formik.touched.template_variation_name ?
                        <>{formik.errors.template_variation_name}</> : null}</div>
                </Form.Group>

                <Form.Group controlId={"folder_upload"}>
                    <div className={"branded_text"}>Upload Ad Template Bulksheets</div>
                    <label className="custom-file-upload btn brande">
                        <input type="file" id={"files_input"} data-testid={"file_upload"} multiple onClick={() => {
                            // setErrorsFound(false)
                        }} onChange={() => {
                            UploadFiles('files_input', setStatusMessages, setCsvJson)
                        }}/>
                    </label>
                </Form.Group>


                <Form.Group controlId={"image_upload"}>
                    <div className={"branded_text"}>Upload Image Files</div>
                    <label className="custom-file-upload btn brande">
                        <input type="file" id={"image_input"} data-testid={"image_upload"} multiple onClick={() => {
                            // setErrorsFound(false)
                        }} onChange={() => {
                            StoreImageFileReference('image_input', setStatusMessages, setImageStoreRefs)
                        }}/>
                    </label>
                </Form.Group>

                {errorsFromAPIFound ?
                    <button className="btn btn-primary mr-1" onClick={() => {
                        DownloadFiles(validatedJson)
                    }} type={"button"}>Download Error
                        Files</button> :
                    <></>
                }

                {formik.dirty
                    ? submission_state === "submitting"
                        ? <Spinner animation={"border"}/>
                        : < button className="btn branded_primary_button" type={"submit"}>Submit</button>
                    :
                    < button className="btn branded_primary_button" style={{opacity: 0.5}}>Submit</button>
                }

            </form>
        </>
    )
}