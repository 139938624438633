import React from "react"
import {Redirect, Route, useLocation} from "react-router-dom"
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import LoadingScreen from "../loading_screen/loading_screen";

const PreSharedPasswordRoute=({component,...args})=>(
     <Route
        component={component}
    />
);


const ProtectedRoute = ({component, ...args}) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <LoadingScreen loading_text={"Loading"}/>
        })}
        {...args}
    />
);


//New approach to auth
//with a protected route
//check if user is logged in
//if they are not logged in
//load the login page component with the route they are trying to access as a prop
//pass the route as a prop to the LoginButton component

export  {ProtectedRoute,PreSharedPasswordRoute};
