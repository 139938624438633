import React from 'react';
import background_image from "../imgs/grey-gb-1280.png";
import {Spinner} from 'react-bootstrap'


export function LoadingScreenTest() {
    return (
        <LoadingScreen loading_text={"Loading"}/>
    )
}

export default function LoadingScreen(props) {
    const {loading_text} = props
    return (
        <div style={{
            backgroundImage: `url(${background_image})`,
            height: '720px',
            width: '100%',
            backgroundColor: ""
        }}>
            <div className={"loading_screen_container"}><p className={"branded_h1"}>{loading_text}</p> <Spinner className={"branded_loading_spinner"} animation={"border"}/> </div>

        </div>
    )


}