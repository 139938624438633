import React from "react"

import ReactDOM from "react-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import * as app from "./app/app";
import 'bootstrap/dist/css/bootstrap.min.css';
import css from "./css/site.css"
import {createBrowserHistory} from 'history';
import {Router} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {AppErrorUserView, SentryErrorBoundaryFallbackFunction} from "./errors/error";
import LogRocket from 'logrocket';


LogRocket.init('3imz7x/adpaca');

Sentry.init({
    dsn: "https://eb5202d586bd4de3b76691af9ec85acf@o388766.ingest.sentry.io/5852870",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    //This function is required so that user is correctly redirected to protected_url after auth
    //https://github.com/auth0/auth0-react/issues/53
    history.replace(appState?.returnTo || window.location.pathname);
};

//Auth0 redirectURI is set to our /authenticate route to allow us to control where the user is redirected to after auth
//We can't set the redirect uri dynamically because it first has to be added to the list of allowable callbacks within our Auth0 Application
//Also we can't whitelist dynamic urls (Auth0 prevents it)
//onRedirectCallback is ran each time a user is returned from auth0 domain

if (document.getElementById("app-root")) {

    ReactDOM.render(
         <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
         <SentryErrorBoundaryFallbackFunction error={error} componentStack={componentStack} resetError={resetError}/>
        )}
      >
            <Auth0Provider
                domain="dev-vm-oe6fe.eu.auth0.com"
                clientId="ZcCfjsCPkEL72oVqDl5y3CAriAJroTvy"
                audience="https:/creative_approvals_tool/api"
                scope="read:current_user update:current_user_metadata"
                redirectUri={`${window.location.origin}/authenticate`}
                onRedirectCallback={onRedirectCallback}
            >
                <Router history={history}>
                    <app.App/>
                </Router>

            </Auth0Provider>
        </Sentry.ErrorBoundary>
        ,
        document.getElementById("app-root")
    )

}