import React from "react";
import {FacebookTruncatedTextField} from "./TextTruncators";


export default function InstagramSingleImageFeedAd(props) {

    const card_data_model = props.ad_preview_fields


    const call_to_action = card_data_model["Call To Action"]
    const company_logo = card_data_model["Logo File Name"]
    const company_name = card_data_model["Company Name"]
    const body = card_data_model['Body']
    const link=card_data_model['Link']
    const image_file_name = card_data_model['Image File Name']

    const truncate_body_at_char_num=95

    return (
        <>
            <link href={"/ad_template_styles/instagram_single_image_feed_ad/styles/preview_css.css"} rel={"stylesheet"}
                  type={"text/css"}/>
            <div className="_831c">
                <div className="_7wp8">
                    <div className="_7wp9">
                        <div className="_7ztw _1-qs">


                        </div>
                    </div>
                </div>
                <div className="_91kv">
                    <div>
                        <div className="_2ph-">
                            <div className="qi2u98y8">
                                <div className="_2qhi">
                                    <div className="_8anb"><img className="_8anc img"
                                                                src="https://business.facebook.com/images/instagram/ads/preview/header-background.png"
                                                                alt=""/></div>
                                    <div className="_8b2u _3qn7 _61-3 _2fyi _3qng">
                                        <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                            <div className="_3qn7 _61-0 _2fyi _3qnf"><img className="_8b2v img"
                                                                                          src={company_logo}
                                                                                          alt=""/></div>
                                        </div>
                                        <div className="_6g3g" style={{flex: '2 1 auto', order: 0}}>
                                            <div className="_8b2w _3qn7 _61-0 _2fyh _3qnf">
                                                <div className="_6g3g _8b2x"
                                                     style={{flex: '0 1 auto', order: 0}}>{company_name}
                                                </div>
                                                <div className="_6g3g _91wz"
                                                     style={{flex: '0 1 auto', order: 0}}>Sponsored
                                                </div>
                                            </div>
                                        </div>
                                        <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                            <div className="_3qn7 _61-0 _2fyi _3qnf"><i alt=""
                                                                                        data-visualcompletion="css-img"
                                                                                        className="img" style={{
                                                backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/8QbqD3OQUdX.png")',
                                                backgroundPosition: '-17px -157px',
                                                backgroundSize: 'auto',
                                                width: '16px',
                                                height: '16px',
                                                backgroundRepeat: 'no-repeat',
                                                display: 'inline-block'
                                            }}/></div>
                                        </div>
                                    </div>
                                    <div><img className="_8b2z img"
                                              src={image_file_name}
                                              alt=""/></div>
                                    <div className="_8c1l">
                                        <div className="_8c1m _3qn7 _61-3 _2fyi _3qng">
                                            <div className="_3ma">{call_to_action}</div>
                                            <i alt="" data-visualcompletion="css-img" className="img" style={{
                                                backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yE/r/8QbqD3OQUdX.png")',
                                                backgroundPosition: '-69px -116px',
                                                backgroundSize: 'auto',
                                                width: '12px',
                                                height: '12px',
                                                backgroundRepeat: 'no-repeat',
                                                display: 'inline-block'
                                            }}/></div>
                                    </div>
                                    <div className="_8fqr">
                                        <div className="_8d5n _3qn7 _61-3 _2fyi _3qng">
                                            <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                                <div className="_3qn7 _61-0 _2fyi _3qnf">
                                                    <div className>
                                                        <button aria-label="Like" className="_8d5q"><img
                                                            className="_8d5o img"
                                                            src="https://business.facebook.com/images/instagram/ads/preview/Like.png"
                                                            alt=""/></button>
                                                    </div>
                                                    <button aria-label="Comment" className="_8d5q"><img
                                                        className="_8d5o img"
                                                        src="https://business.facebook.com/images/instagram/ads/preview/Comment.png"
                                                        alt=""/></button>
                                                    <button aria-label="Share" className="_8d5q"><img
                                                        className="_8d5o img"
                                                        src="https://business.facebook.com/images/instagram/ads/preview/direct-share.png"
                                                        alt=""/></button>
                                                </div>
                                            </div>
                                            <div className="_6g3g" style={{flex: '0 1 auto', order: 0}}>
                                                <button aria-label="Save" className="_8d5q"><img className=" _8d5p img"
                                                                                                 src="https://business.facebook.com/images/instagram/ads/preview/save.png"
                                                                                                 alt=""/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_8dh2" style={{paddingLeft: '10px'}}>
                                        <div><span className="_8dh3 _9hws"/><span/> <span className="_8rs5 _9hvh"
                                                                                          data-ad-preview="ig-message"
                                                                                          direction><span className="newline_behavior"
                                            style={{fontSize: '11px'}}><FacebookTruncatedTextField  full_text={body}
                                   truncate_at_char_num={truncate_body_at_char_num}
                                   text_for_toggle="...More"></FacebookTruncatedTextField></span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden_elem">
                            <div className="_2ph- _228q" id="f1314c6d4ff32e8">
                                <div className="hidden_elem" data-ad-preview="previewContainer" id="f1314c6d4ff32e8-1"/>
                                <div className id="f1314c6d4ff32e8-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}